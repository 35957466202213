import React from "react";
import styled from "styled-components";
import ProgressItem from "../../atoms/ProgressItem/ProgressItem";

const PaymentProgress = ({ orderPlaced }) => (
  <Progress>
    <ProgressItem
      label={"Dostawa"}
      step={"1"}
      to={"/checkout"}
      linkActive={!orderPlaced}
      className="gtm_btn_checkout"
    />
    <ProgressItem label={"Podsumowanie i zamówienie"} isActive step={"2"} />
    <ProgressItem label={"Płatność i potwierdzenie"} isDisabled step={"3"} />
  </Progress>
);

export default PaymentProgress;

const Progress = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;
