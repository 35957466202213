import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { PRIMARY, SECONDARY } from "../../../vars/vars";

const FooterLink = ({ display, to, direct, specialKits }) => (
  <Wrapper>
    {direct ? (
      <StyledHref href={to} target={"_blank"}>
        {display}
      </StyledHref>
    ) : (
      <StyledLink
        to={to}
        className={`${to === "/contact" && "gtm_btn_contactus"} ${
          to === "/terms" && "gtm_btn_termsandconditions"
        } ${to === "/shipping" && "gtm_btn_shippingoptions"}
        ${to === "/returns" && "gtm_btn_returns"}
        ${specialKits ? "gtm_footer_special_kits" : ""}
        `}
      >
        {display}
      </StyledLink>
    )}
  </Wrapper>
);

export default FooterLink;

const Wrapper = styled.div`
  display: block;
  margin: 5px 0;
`;

const StyledHref = styled.a`
  color: ${SECONDARY};
  font-weight: 400;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;

  &:hover {
    color: ${PRIMARY};
  }

  &:before {
    content: "\f101";
    font-family: "FontAwesome", sans-serif;
    display: inline-block;
    margin-right: 5px;
  }
`;

const StyledLink = styled(Link)`
  color: ${SECONDARY};
  font-weight: 400;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;

  &:hover {
    color: ${PRIMARY};
  }

  &:before {
    content: "\f101";
    font-family: "FontAwesome", sans-serif;
    display: inline-block;
    margin-right: 5px;
  }
`;
