import React from "react";
import DICT from "../../../vars/dictionary";
import styles from "./Category.module.scss";

const Category = ({ name, children }) => 
(
  <div className={styles.Category}>
    <div className={styles.Header}>{DICT[name]}</div>
    {children}
  </div>
);

export default Category;
