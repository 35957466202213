import DesktopDropdownMenu from "../DesktopDropdownMenu/DesktopDropdownMenu";
import Vehicles from "../dropdowns/Vehicles/Vehicles";
import React from "react";

export const links = [
  { to: "/", display: "strona główna" },
  {
    display: "Gdzie kupić",
    to: "/distributors",
  },
  {
    display: "Popularne auta",
    children: (
      <DesktopDropdownMenu>
        <Vehicles />
      </DesktopDropdownMenu>
    ),
  },
];
