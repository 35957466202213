export const tabs = [
  {
    name: "Detale",
  },
  {
    name: "Pasuje do",
  },
  {
    name: "Pokrewne części",
  },
  {
    name: "Informacje techniczne"
  }
];
