import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import OrderSummary from "../../organisms/OrderSummary/OrderSummary";
import FormAddress from "../../organisms/forms/FormAddress/FormAddress";
import CheckoutProgress from "../../molecules/CheckoutProgress/CheckoutProgress";
import Sticky from "../../atoms/Sticky/Sticky";

const Checkout = () => {
  return (
    <Container>
      <CheckoutWrapper>
        <div>
          <CheckoutProgress />
          <FormAddress />
        </div>
        <StyledAside>
          <Sticky>
            <OrderSummary header={"Podsumowanie"} />
          </Sticky>
        </StyledAside>
      </CheckoutWrapper>
    </Container>
  );
};

export default Checkout;

const CheckoutWrapper = styled.div`
  padding-top: 92px;
  padding-bottom: 30px;
  display: grid;

  @media screen and (min-width: 1000px) {
    column-gap: 20px;
    grid-template-columns: 2fr 1fr;
  }
`;

const StyledAside = styled.aside`
  display: none;

  @media screen and (min-width: 1000px) {
    display: block;
    height: 100%;
  }
`;
