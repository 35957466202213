import React from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../../../context/AppContext";
import { PRIMARY } from "../../../vars/vars";
import { images, altImages } from "./images";
import styled from "styled-components";
import DICT from "../../../vars/dictionary";
import other from "../../../assets/ribbon/no-image.webp";
import other_White from "../../../assets/ribbon/no-image_White.webp";
import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/src/css/themes/default/index.scss";
import "./CarouselRibbon.scss";
import { ribbonCategoriesOrder as initialCategories } from "../../../vars/orderLists";

const CarouselRibbon = () => {
  const { categories } = useAppContext();

  const isActive = (category) => {
    const params = new URL(document.location).searchParams;
    return params.has("category") && params.get("category") === category;
  };

  return (
    <div className="carousel">
      <Splide
        className="CarouselWrapper"
        tag="section"
        hasTrack={false}
        options={{
          wheel: true,
          width: 1140,
          perPage: 6,
          gap: "5px",
          perMove: 1,
          pagination: false,
          breakpoints: {
            500: {
              perPage: 1,
            },
            800: {
              perPage: 3,
            },
            1000: {
              perPage: 4,
            },
            1200: {
              perPage: 5,
            },
          },
        }}
      >
        <div className="custom-wrapper">
          <SplideTrack>
            {(categories && categories.length > 0
              ? categories
              : initialCategories
            )
              .filter((el) => el !== "#N/D")
              .map((category, i) => (
                <SplideSlide key={category} index={i}>
                  <div className={`carousel__lines ${category}`}>
                    <Link
                      to={{
                        pathname: "/parts",
                        search: `?category=${category}`,
                      }}
                      className="gtm_ribbon"
                    >
                      <StyledLink
                        isActive={isActive(category)}
                        className="carousel__link gtm_ribbon"
                      >
                        <StyledImage
                          className={`carousel__image image${category}`}
                          image={images[category] ? images[category] : other}
                          imageAlt={
                            altImages[category]
                              ? altImages[category]
                              : other_White
                          }
                          isActive={isActive(category)}
                        />
                        <Text
                          className={`carousel__text text${category} gtm_ribbon`}
                        >
                          {DICT[category] || "Inne"}
                        </Text>
                      </StyledLink>
                    </Link>
                    <div className="divider"></div>
                  </div>
                </SplideSlide>
              ))}
          </SplideTrack>
        </div>
      </Splide>
    </div>
  );
};

export default CarouselRibbon;
const Text = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
`;
const StyledLink = styled.div`
  border-radius: 5px;
  width: 140px;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: ${(props) => (props.isActive ? PRIMARY : null)};
  color: ${(props) => (props.isActive ? "white" : "black")};

  &:hover {
    color: white;
    background-color: ${PRIMARY};
  }
`;
const StyledImage = styled.div`
  width: 40px;
  height: 40px;
  pointer-events: none;
  background-image: url(${(props) =>
    props.isActive ? props.imageAlt : props.image});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  &:hover {
    background-image: url(${(props) => props.imageAlt});
  }
`;
