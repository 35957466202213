import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { PRIMARY } from "../../../vars/vars";
import { useHistory } from "react-router";

const CookieConsent = ({ onCookieConsentResponse }) => {
  const history = useHistory();

  const acceptAllCookies = () => {
    onCookieConsentResponse("agree");
  };

  const openCookieSettings = () => {
    onCookieConsentResponse("manage");
  };

  function handleLinkClick() {
    history.push("/cookies");
  }

  return (
    <Wrapper visible={true}>
      <Container>
        <Content>
          <Text>
            Używamy plików cookie i innych podobnych technologii, aby zapewnić
            sprawne i bezpieczne działanie naszej witryny. Dowiedz się więcej o
            tym, jak używamy plików cookie i jak możesz zmienić swoje
            preferencje, czytając naszą{" "}
            <MyLink
              onClick={handleLinkClick}
              aria-label="Link do polityki cookies"
            >
              Politykę "Cookies"
            </MyLink>
            .
          </Text>
          <Agree
            onClick={() => acceptAllCookies()}
            aria-label="Przycisk zgody na politykę cookies"
          >
            Akceptuję
          </Agree>
          <Decline
            onClick={() => openCookieSettings()}
            aria-label="Przycisk zarządzaj polityką cookies"
          >
            Zarządzaj
          </Decline>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default CookieConsent;

const Wrapper = styled.div`
  display: ${({ visible }) => (visible ? "block" : "none")};
  padding: 20px 0;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 505;
`;

const Text = styled.div`
  padding: 5px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const Button = styled.div`
  padding: 5px;
  text-align: center;
  margin: 0 5px;
  width: 220px;
  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    margin: 10px 0 5px 0;
  }
`;

const Agree = styled(Button)`
  background-color: #b30000;
  color: white;
`;

const Decline = styled(Button)`
  background-color: lightgray;
`;

const MyLink = styled.span`
  color: ${PRIMARY};
  &:hover {
    cursor: pointer;
  }
`;
