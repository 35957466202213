import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faCubes } from "@fortawesome/free-solid-svg-icons/faCubes";
import { PRIMARY } from "../../../vars/vars";
import React from "react";

export const services = [
  {
    icon: <FontAwesomeIcon icon={faCheckCircle} size={"2x"} color={PRIMARY} />,
    header: "Rady eksperta",
    text: "Sprawdź dopasowanie części do pojazdu",
  },
  {
    icon: <FontAwesomeIcon icon={faCubes} size={"2x"} color={PRIMARY} />,
    header: "Kontrola stanów magazynowych",
    text: "Aby zapewnić stałą dostępność.",
  },
  {
    icon: <FontAwesomeIcon icon={faCalendarAlt} size={"2x"} color={PRIMARY} />,
    header: "Sprawdzona i szybka dostawa",
    text: "Sprawdź warunki wysyłki tutaj.",
    to: "/shipping",
  },
];
