import React from "react";
import styled from "styled-components";
import ProgressItem from "../../atoms/ProgressItem/ProgressItem";

const CheckoutProgress = () => (
  <Progress>
    <ProgressItem label={"Dostawa"} isActive step={"1"} />
    <ProgressItem label={"Podsumowanie i płatność"} isDisabled step={"2"} />
    <ProgressItem label={"Potwierdzenie płatności"} isDisabled step={"3"} />
  </Progress>
);

export default CheckoutProgress;

const Progress = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;
