import React, { useEffect } from "react";
import styled from "styled-components";
import FormLabel from "../../atoms/FormLabel/FormLabel";
import FormInput from "../../atoms/FormInput/FormInput";
import FormError from "../../atoms/FormError/FormError";
import { useField, useFormikContext } from "formik";

const FormItem = ({ label, required, children, ...props }) => {
  const {
    values: { billcountryname },
    touched,
    setFieldValue,
  } = useFormikContext();
  const [field, meta] = useField(props);

  useEffect(() => {
    if (
      billcountryname &&
      billcountryname.trim() !== "" &&
      touched.billcountryname
    ) {
      setFieldValue("vat", `${billcountryname}`);
    }
  }, [billcountryname, touched.billcountryname, setFieldValue, props.name]);

  return (
    <StyledComponent>
      {label && (
        <FormLabel
          text={label}
          required={label !== "VIN" ? required : ""}
          htmlFor={props.id || props.name}
        />
      )}
      <FormInput
        {...field}
        {...props}
        invalid={meta.touched && !!meta.error}
        valid={meta.touched && !meta.error}
      >
        {children}
      </FormInput>
      {meta.touched && meta.error && <FormError text={meta.error} />}
    </StyledComponent>
  );
};

export default FormItem;

const StyledComponent = styled.div`
  margin-top: 10px;
`;
