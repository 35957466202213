import React, { useEffect, useState, useMemo, useCallback } from "react";
import styled from "styled-components";
import CatalogueItemCard from "../../molecules/CatalogueItemCard/CatalogueItemCard";
import DICT from "../../../vars/dictionary";
import styles from "./SearchResults.module.scss";
import { groupDescriptionOrder } from "../../../vars/orderLists";
import { useInView } from "react-intersection-observer";

const ITEMS_PER_CHUNK = 6;

const getGroupOrder = (groupName) => {
  const index = groupDescriptionOrder.findIndex(
    (item) => item.toLowerCase() === groupName.toLowerCase()
  );
  return index === -1 ? groupDescriptionOrder.length : index;
};

const groupBy = (array, property) => {
  const props = property.split(".");
  return array.reduce((hash, item) => {
    const key = props.reduce((acc, prop) => acc && acc[prop], item);
    if (!hash[key]) hash[key] = [];
    hash[key].push(item);
    return hash;
  }, {});
};

const sortByAvailability = (a, b) => {
  if (a.item.available && !b.item.available) return -1;
  if (!a.item.available && b.item.available) return 1;
  if (a.item.available && !b.item.manufacturerstock) return -1;
  if (!a.item.available && b.item.manufacturerstock) return 1;
  if (!a.item.available && !b.item.manufacturerstock) return -1;
  return 0;
};

const Group = ({
  group,
  items,
  visibleItems,
  onLoadMore,
  addToCart,
  buttonText,
  isLoading,
}) => {
  const [ref, inView] = useInView({
    threshold: 0,
    rootMargin: "100px",
  });

  useEffect(() => {
    if (inView && !isLoading) {
      onLoadMore(group.name);
    }
  }, [inView, isLoading, group.name, onLoadMore]);

  const groupItems = items[group.name] || [];
  const visibleCount = visibleItems[group.name] || ITEMS_PER_CHUNK;
  const displayedItems = groupItems.slice(0, visibleCount);
  const hasMore = visibleCount < groupItems.length;

  return (
    <div className="group-container">
      <StyledWrapper>
        <StyledTitle>
          {DICT[group.displayName] || group.displayName}
        </StyledTitle>
      </StyledWrapper>
      <section className={styles.SearchResults}>
        {displayedItems.map((parts) => (
          <StyledDiv key={parts.item.id}>
            <CatalogueItemCard
              part={parts.item}
              parameter={parts.parameter}
              addToCart={addToCart}
              buttonText={buttonText}
            />
          </StyledDiv>
        ))}
      </section>

      {hasMore && (
        <div ref={ref} style={{ height: "20px", margin: "20px 0" }}>
          {isLoading && (
            <LoadingIndicator>Ładuję kolejne przedmioty...</LoadingIndicator>
          )}
        </div>
      )}
    </div>
  );
};

const SearchResults = ({ items, addToCart, buttonText }) => {
  const [filteredItems, setFilteredItems] = useState([]);
  const [allItems, setAllItems] = useState({});
  const [visibleItems, setVisibleItems] = useState({});
  const [loadingGroups, setLoadingGroups] = useState(new Set());
  const [error, setError] = useState(null);

  const params = new URL(document.location).searchParams;

  useEffect(() => {
    try {
      if (!items?.length) {
        setFilteredItems([]);
        return;
      }
      const validItems = items
        .filter((item) => item.item)
        .sort(sortByAvailability);

      setFilteredItems(validItems);
    } catch (err) {
      setError(`Error processing items: ${err.message}`);
    }
  }, [items]);

  useEffect(() => {
    try {
      if (!filteredItems.length) {
        setAllItems({});
        return;
      }

      const grouped = groupBy(filteredItems, "groupdescription");
      setAllItems(grouped);

      const initialVisibleItems = {};
      Object.keys(grouped).forEach((key) => {
        initialVisibleItems[key] = Math.min(
          ITEMS_PER_CHUNK,
          grouped[key].length
        );
      });
      setVisibleItems(initialVisibleItems);
    } catch (err) {
      setError(`Error grouping items: ${err.message}`);
    }
  }, [filteredItems]);

  const getSortedGroups = useMemo(() => {
    try {
      return Object.keys(allItems)
        .filter((key) =>
          params.has("groups") ? key === params.get("groups") : true
        )
        .map((groupName) => ({
          name: groupName,
          index: getGroupOrder(groupName),
          displayName: groupName === "undefined" ? "Results" : groupName,
        }))
        .sort((a, b) => a.index - b.index);
    } catch (err) {
      setError(`Error sorting groups: ${err.message}`);
      return [];
    }
  }, [allItems, params]);

  const handleLoadMore = useCallback(
    (groupName) => {
      if (!loadingGroups.has(groupName)) {
        setLoadingGroups((prev) => new Set([...prev, groupName]));

        setTimeout(() => {
          setVisibleItems((prev) => ({
            ...prev,
            [groupName]: prev[groupName] + ITEMS_PER_CHUNK,
          }));

          setLoadingGroups((prev) => {
            const newSet = new Set(prev);
            newSet.delete(groupName);
            return newSet;
          });
        }, 300);
      }
    },
    [loadingGroups]
  );

  if (error) {
    return <StyledErrorMsg>{error}</StyledErrorMsg>;
  }

  return (
    <div className="search-results-container">
      {getSortedGroups.map((group) => (
        <Group
          key={group.name}
          group={group}
          items={allItems}
          visibleItems={visibleItems}
          onLoadMore={handleLoadMore}
          addToCart={addToCart}
          buttonText={buttonText}
          isLoading={loadingGroups.has(group.name)}
        />
      ))}
    </div>
  );
};

export default SearchResults;

const LoadingIndicator = styled.div`
  padding: 20px;
  text-align: center;
  color: #666;
  font-weight: bold;
  margin: 20px 0;
`;

const StyledTitle = styled.p`
  color: black;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  @media (max-width: 420px) {
    font-size: 20px;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  padding-bottom: 30px;
`;

const StyledWrapper = styled.div`
  width: 95%;
  max-width: 750px;
  margin-top: 20px;
  border-top: #ed3730 solid 4px;
  padding-top: 10px;
`;

const StyledErrorMsg = styled.div`
  margin: 50px auto;
  max-width: 600px;
  width: 60%;
  text-align: center;
  font-weight: bold;
  padding: 20px;
  border: 1px solid black;
`;
