import React from "react";
import styled from "styled-components";
import { PRIMARY } from "../../../vars/vars";

const Price = ({ vat, isVatIncluded, promo, baseprice }) => (
  <StyledWrapper>
    <StyledPrice>
      {vat} ZŁ {promo && <OldPrice>{baseprice} ZŁ</OldPrice>}
    </StyledPrice>
    {isVatIncluded ? <VAT>Cena zawiera vat</VAT> : <></>}
    {promo && (
      <OldPriceInfo>
        Najniższa cena z 30 dni przed obniżką: {baseprice} ZŁ
      </OldPriceInfo>
    )}
  </StyledWrapper>
);

export default Price;

const StyledWrapper = styled.div`
  width: 100%;
`;

const VAT = styled.div`
  color: #666666;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 10px;
`;

const StyledPrice = styled.div`
  font-size: 26px;
  line-height: 30px;
  color: ${PRIMARY};
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  display: flex;
`;

const OldPrice = styled.span`
  line-height: 30px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  color: black;
  text-decoration: line-through;
  font-size: 18px;
  margin-left: 8px;
`;

const OldPriceInfo = styled.span`
  color: #666666;
  font-size: 13px;
`;
