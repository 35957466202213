import React, { createContext, useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { COOKIESNAME } from "./../vars/vars";

export const ConsentContext = createContext();

const initialCookieState = { accepted: false, preferences: ["niezbędne"] };

const ConsentContextProvider = (props) => {
  const [cookiesConsentCtx, setCookiesConsentCtx] = useState(
    initialCookieState
  );
  const setCookies = (name, value, options = {}) => {
    const consents = JSON.stringify(value);
    return Cookies.set(name, consents, options);
  };

  const getCookies = (name) => {
    const consents = Cookies.get(name);
    return consents ? JSON.parse(consents) : null;
  };
  useEffect(() => {
    const data = getCookies(COOKIESNAME);

    if (data && data.accepted) {
      setCookiesConsentCtx(data);
    } else {
      setCookies(COOKIESNAME, initialCookieState, { expires: 7 });
    }
  }, []);

  useEffect(() => {
    if (cookiesConsentCtx.accepted) {
      setCookies(COOKIESNAME, cookiesConsentCtx);
    }
  }, [cookiesConsentCtx]);

  const value = {
    cookiesConsentCtx,
    setCookiesConsentCtx,
  };

  return (
    <ConsentContext.Provider value={value}>
      {props.children}
    </ConsentContext.Provider>
  );
};

export const useConsentContext = () => useContext(ConsentContext);
export default ConsentContextProvider;
