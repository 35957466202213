import React from "react";
import Header from "../../atoms/Header/Header";
import Line from "../../atoms/Line/Line";
import styled from "styled-components";

const UnderlinedHeader = ({ text, bolder, soft }) => (
  <StyledUnderlinedHeader>
    <Header text={text} bolder={bolder} />
    {!soft && <Line bolder={bolder} />}
  </StyledUnderlinedHeader>
);

export default UnderlinedHeader;

const StyledUnderlinedHeader = styled.div``;
