import React from "react";
import styled from "styled-components";
import SummaryHeader from "../../atoms/SummaryHeader/SummaryHeader";

const OrderPageSummary = ({ subtotal, shipping, total, currency, tax }) => (
  <StyledComponent>
    <SummaryHeader text={"Podsumowanie"} />
    <div>
      <AdditionalData>
        <div>Suma częściowa</div>
        <div>
          {subtotal.toFixed(2)} {currency}
        </div>
      </AdditionalData>
      <AdditionalData>
        <div>Dostawa</div>
        <div>
          {shipping.toFixed(2)} {currency}
        </div>
      </AdditionalData>
      <AdditionalData>
        <div>Podatek</div>
        <div>
          {tax.toFixed(2)} {currency}
        </div>
      </AdditionalData>
      <AdditionalData>
        <div>W sumie</div>
        <Summary>
          {total.toFixed(2)} {currency}
        </Summary>
      </AdditionalData>
    </div>
  </StyledComponent>
);

export default OrderPageSummary;

const StyledComponent = styled.section`
  padding: 30px;
  background-color: #fafafa;
`;

const AdditionalData = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-family: "Open Sans", sans-serif;
  color: #666666;
  padding: 8px 0;
  font-size: 12px;
`;

const Summary = styled.div`
  font-weight: bold;
  margin-top: 20px;
`;
