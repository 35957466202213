import React from "react";
import styled from "styled-components";
import { PRIMARY } from "../../../vars/vars";
import { useHistory } from "react-router";

const DetailsAddButton = ({
  part,
  availability,
  record,
  quantity,
  addHandler,
  buttonText,
  enquiryHandler,
  basketQuantity,
  defaultStockLocation,
}) => {
  const history = useHistory();

  return (
    <>
      {quantity + basketQuantity <=
      availability.location +
        availability.manufacturer +
        availability.intransit ? (
        <StyledButton
          adding={buttonText !== "Dodaj do koszyka"}
          disabled={buttonText !== "Dodaj do koszyka"}
          onClick={() => {
            addHandler(record, quantity, part, defaultStockLocation);
          }}
          className="gtm_btn_addtocart"
        >
          {buttonText}
        </StyledButton>
      ) : (
        <StyledButton onClick={enquiryHandler}>Wyślij zapytanie</StyledButton>
      )}

      <StyledSecondaryButton
        onClick={() => history.push("/distributors")}
        className="gtm_btn_distributors"
      >
        Kup od dystrybutora
      </StyledSecondaryButton>
    </>
  );
};

export default DetailsAddButton;

const StyledSecondaryButton = styled.button`
  display: inline-block;
  padding: 10px 20px;
  background: #292b29;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.1s ease;
  white-space: nowrap;
  font-family: "Open Sans", sans-serif;

  &:hover {
    background-color: ${PRIMARY};
  }
`;

const StyledButton = styled.button`
  font-family: "Open Sans", sans-serif;
  opacity: ${(props) => (props.adding ? "0.5" : 1)};
  text-transform: uppercase;
  height: 48px;
  margin: 10px 0;
  font-size: 18px;
  display: inline-block;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  color: white;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.1s ease;
  white-space: nowrap;
  background-color: #50be19;

  &:hover {
    background-color: #444444;
  }

  &:active,
  &:focus {
    outline: none;
    border: none;
  }
`;
