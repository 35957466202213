import { useEffect } from "react";
import { useFormikContext } from "formik";

import useBasketActions from "../../../../hooks/useBasketActions";
import { useAppContext } from "../../../../context/AppContext";

const AutoCountryHandler = () => {
  const { values } = useFormikContext();
  const { updateCountryWithTax, updateFieldHandler } = useBasketActions();
  const { basket } = useAppContext();

  useEffect(() => {
    async function updateData() {
      if (values.country) {
        if (basket.shipcountry !== values.country && values.delivery !== "") {
          values.delivery = "";
          await updateFieldHandler("shippingmethod", values.delivery);
        }
        await updateCountryWithTax(values.country, values.isCompany);
      }
    }
    updateData();
  }, [values.country, values.isCompany]);

  useEffect(() => {
    async function updateData() {
      if (values.delivery) {
        await updateFieldHandler("shippingmethod", values.delivery);
      }
    }
    updateData();
  }, [values.delivery]);
  return null;
};

export default AutoCountryHandler;
