import React from "react";
import styled from "styled-components";
import { PRIMARY } from "../../../vars/vars";

const MiniBasketSubtotal = ({ subtotal, currency }) => (
  <StyledMiniBasketTotal>
    <Text>Suma częściowa:</Text>
    <Amount>
      {subtotal} {currency}
    </Amount>
  </StyledMiniBasketTotal>
);

export default MiniBasketSubtotal;

const StyledMiniBasketTotal = styled.div`
  padding: 13px 20px 10px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  display: flex;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  justify-content: space-between;
  font-weight: 700;
  text-transform: uppercase;
`;

const Text = styled.div`
  color: #444444;
`;

const Amount = styled.div`
  color: ${PRIMARY};
`;
