import React from "react";
import styled from "styled-components";
import placeholderWebp from "./../../../assets/placeholders/pedders_placeholder.webp";
import placeholder from "./../../../assets/placeholders/pedders_placeholder.jpg";

const CheckoutLine = ({ item, currency }) => (
  <Line>
    <Photo>
      {item.item.photos.length ? (
        <img src={item.item.photos[0].url} alt={item.displayname} />
      ) : (
        <picture>
          <source srcSet={placeholderWebp} type="image/webp" />
          <img src={placeholder} alt={item.displayname} />
        </picture>
      )}
    </Photo>
    <Details>
      <span>{item.item.description}</span>
      <span>{item.displayname}</span>

      <div>Ilość: {item.quantity}</div>
    </Details>
    <Price>
      <p>
        {item.grosamount} {currency}
      </p>
    </Price>
  </Line>
);

export default CheckoutLine;

const Line = styled.div`
  font-family: "Open Sans", sans-serif;
  margin-top: 15px;
  display: grid;
  grid-template-columns: 100px 5fr 2fr;
  gap: 10px;
  padding-bottom: 15px;
  &:not(:last-of-type) {
    border-bottom: 1px solid #ccc;
  }

  @media (min-width: 576px) {
    gap: 20px;
  }
  @media (min-width: 1000px) {
    gap: 8px;
  }
`;

const Details = styled.div`
  color: #666666;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Photo = styled.div`
  width: 100px;
  height: 100px;
  padding: 3px;
  border: 1px solid grey;
  display: fels;
  justify-content: center;
  align-items: center;
  background-color: white;

  img {
    width: 100%;
    object-fit: contain;
  }
`;

const Price = styled.div`
  color: #666666;
  font-size: 12px;
  text-align: right;
  font-weight: 700;
`;
