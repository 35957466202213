import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import PartFinderButton from "../../atoms/PartFinderButton/PartFinderButton";
import PartFinderHeader from "../../atoms/PartFinderHeader/PartFinderHeader";
import layoutContext from "../../../context/LayoutContext";
import { BarLoader } from "react-spinners";
import styles from "./PartFinder.module.scss";
import { useAppContext } from "../../../context/AppContext";

const PartFinder = ({ history }) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const context = useContext(layoutContext);
  const { options } = useAppContext();
  const [make, setMake] = useState(queryParameters.get("make") || "");
  const [model, setModel] = useState(queryParameters.get("model") || "");
  const [year, setYear] = useState(queryParameters.get("year") || "");
  const [models, setModels] = useState([]);
  const [years, setYears] = useState([]);
  const data = {
    action: "getApplications",
    language: "polish",
    source: "pedderspl",
    manufacturergroup: "5f9bda55dcdf6b0c04f1878b",
    currency: "pln",
    customer: "guest",
  };

  const createCatalogueHeader = () => {
    let header = `${make} ${model} ${year}`;
    context.setCatalogueHeader(header);
  };

  const submitHandler = () => {
    createCatalogueHeader();
    const params = new URL(document.location).searchParams;
    if (params.has("category")) {
      params.set("make", make);
      params.set("year", year);
      params.set("model", model);
      history.push(document.location.pathname + "?" + params.toString());
    } else {
      const url = new URL("parts", window.location);
      url.searchParams.append("make", make);
      url.searchParams.append("year", year);
      url.searchParams.append("model", model);
      window.history.pushState({}, "", url);
      window.location.reload(true);
    }
  };

  const fetchData = async (body) => {
    try {
      const res = await fetch(
        "https://ozparts2.usermd.net/api/external/applications",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const data = await res.json();
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  async function setYearsOptions(data) {
    const res = await fetchData({ ...data, show: false });
    return setYears([...new Set(res.options.year.sort().reverse())]);
  }

  async function setModelsOptions(data) {
    const res = await fetchData({ ...data, show: false });
    return setModels([...new Set(res.options.model.sort())]);
  }

  useEffect(() => {
    if (make.length && options && options.length) {
      setModelsOptions({
        ...data,
        make,
      });
    }
  }, [make, options]);

  useEffect(() => {
    if (model.length && make.length && models.length) {
      setYearsOptions({
        ...data,
        make,
        model,
      });
    }
  }, [model, models]);

  const handleMake = (event) => {
    event.preventDefault();
    setMake(event.target.value);
    setYear("");
    setModel("");
  };

  const handleYearsOption = (event) => {
    event.preventDefault();
    setYear(event.target.value);
  };

  const handleModelsOption = (event) => {
    event.preventDefault();
    setModel(event.target.value);
    setYear("");
  };

  return (
    <section className={styles.PartFinder}>
      <Container>
        <PartFinderHeader text={"Szybka Wyszukiwarka Samochodów"} />
        <StyledMainContainer>
          <StyledContainer>
            <StyledLabel htmlFor="make">Marka</StyledLabel>
            <StyledSelect onChange={handleMake} value={make} id="make">
              <StyledOption value="Wybierz markę...">
                Wybierz markę...
              </StyledOption>
              {options &&
                options.map((item) => (
                  <StyledOption value={item} key={item}>
                    {item}
                  </StyledOption>
                ))}
            </StyledSelect>
          </StyledContainer>
          <StyledContainer>
            <StyledLabel htmlFor="model">Model</StyledLabel>
            <StyledSelect
              onChange={handleModelsOption}
              value={model}
              id="model"
            >
              <StyledOption value="Wybierz model...">
                Wybierz model...
              </StyledOption>
              {models.map((item) => (
                <StyledOption value={item} key={item}>
                  {item}
                </StyledOption>
              ))}
            </StyledSelect>
          </StyledContainer>
          <StyledContainer>
            <StyledLabel htmlFor="year">Rok</StyledLabel>
            <StyledSelect onChange={handleYearsOption} value={year} id="year">
              <StyledOption value="Wybierz rok...">Wybierz rok...</StyledOption>
              {years.map((item) => (
                <StyledOption value={item} key={item}>
                  {item}
                </StyledOption>
              ))}
            </StyledSelect>
          </StyledContainer>
        </StyledMainContainer>
        <PartFinderButton
          disabled={!year}
          submitHandler={submitHandler}
          text={"Pokaż produkty"}
        />
      </Container>
      <BarLoader
        loading={context.loader}
        height={"3px"}
        width={"100%"}
        color="#F54D40"
      />
    </section>
  );
};
export default withRouter(PartFinder);

const StyledMainContainer = styled.div`
  display: flex;
  margin-left: 10px;
  justify-content: space-between;

  @media only screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const StyledLabel = styled.label`
  font-size: 12px;
  color: #666666;
  font-weight: bold;
`;

const StyledOption = styled.option`
  color: rgb(102, 102, 102);
  font-size: 12px;
`;

const StyledSelect = styled.select`
  margin: 0 10px 10px 10px;
  border-radius: 3px;
  border: 1px solid #aaa;
  padding: 5px 10px 4px;
  color: rgb(102, 102, 102);
  font-size: 12px;
  background-color: ${(props) => (props.length ? null : "rgba(0,0,0, 0.05)")};
}

  &:first-of-type {
    margin-left: 0;
  }
`;
