import { lazy } from "react";
import Catalogue from "../components/pages/Catalogue/Catalogue";
import Home from "../components/pages/Home/Home";
import Basket from "../components/pages/Basket/Basket";
import Details from "../components/pages/Details/Details";
import Checkout from "../components/pages/Checkout/Checkout";
import Payment from "../components/pages/Payment/Payment";
import Order from "../components/pages/Order/Order";
import Search from "../components/pages/Search/Search";

import { popularRoutes } from "./popularRoutes";

const LazyAbout = lazy(() => import("../components/pages/About/About"));
const LazyContact = lazy(() => import("./../components/pages/Contact/Contact"));
const LazyCookiePolicy = lazy(() =>
  import("./../components/pages/CookiesPolicy/CookiesPolicy")
);
const LazyDistributors = lazy(() =>
  import("../components/pages/Distributors/Distributors")
);
const LazyFaq = lazy(() => import("./../components/pages/Faq/Faq"));
const LazyFeatures = lazy(() =>
  import("../components/pages/Features/Features")
);
const LazyKits = lazy(() => import("../components/pages/Kits/Kits"));
const LazyPrivacy = lazy(() => import("../components/pages/Privacy/Privacy"));
const LazyReturns = lazy(() => import("./../components/pages/Returns/Returns"));
const LazyShipping = lazy(() =>
  import("./../components/pages/Shipping/Shipping")
);
const LazyTerms = lazy(() => import("./../components/pages/Terms/Terms"));
const LazyWarranties = lazy(() =>
  import("./../components/pages/Warranties/Warranties")
);

const routes = [
  {
    path: "/",
    component: Home,
    exact: true,
  },
  {
    path: "/features",
    component: LazyFeatures,
  },
  {
    path: "/faq",
    component: LazyFaq,
  },
  {
    path: "/returns",
    component: LazyReturns,
  },
  {
    path: "/terms",
    component: LazyTerms,
  },
  {
    path: "/cookies",
    component: LazyCookiePolicy,
  },
  {
    path: "/kits",
    component: LazyKits,
  },
  {
    path: "/warranties",
    component: LazyWarranties,
  },
  {
    path: "/distributors",
    component: LazyDistributors,
  },
  {
    path: "/basket",
    component: Basket,
  },
  {
    path: "/privacy",
    component: LazyPrivacy,
  },
  {
    path: "/shipping",
    component: LazyShipping,
  },
  {
    path: "/about",
    component: LazyAbout,
  },
  {
    path: "/contact",
    component: LazyContact,
  },
  {
    path: "/checkout",
    component: Checkout,
  },
  {
    path: "/payment",
    component: Payment,
  },
  {
    path: "/order/:id",
    component: Order,
  },
  {
    path: "/search/:term",
    component: Search,
  },
  {
    path: "/details/:name",
    component: Details,
  },
  {
    path: "/parts/:make?/:model?/:engine?",
    component: Catalogue,
  },
  ...popularRoutes,
];

export default routes;
