import React, { useState, useEffect } from "react";
import useBasketActions from "../../../hooks/useBasketActions";
import { PRIMARY } from "../../../vars/vars";
import styled from "styled-components";
import { useAppContext } from "../../../context/AppContext";

const DiscountCode = () => {
  const { basket } = useAppContext();
  const [currentCode, setCurrentCode] = useState("");
  const [newCode, setNewCode] = useState("");
  const { updateFieldHandler } = useBasketActions();

  useEffect(() => {
    setCurrentCode(basket.promocode);
    setNewCode(basket.promocode === "" ? "" : newCode);
  }, [basket.promocode]);

  const handlePromoCode = async () => {
    await updateFieldHandler("promocode", !currentCode ? newCode : "");
  };

  return (
    <StyledDiscountCode>
      <StyledLabel htmlFor={"discount"}>
        {currentCode ? "Dodany kod żniżkowy" : "Podaj kod zniżkowy"}
      </StyledLabel>
      {!currentCode ? (
        <StyledInput
          id={"discount"}
          placeholder={"Dodaj kod zniżkowy"}
          onChange={(e) => setNewCode(e.target.value)}
          value={newCode}
        />
      ) : (
        <StyledInput
          id={"discount"}
          disabled
          placeholder={"Dodany kod zniżkowy"}
          value={currentCode}
        />
      )}
      <div>
        <Button onClick={handlePromoCode}>
          {!currentCode ? "Dodaj kod" : "Zmień kod"}
        </Button>
      </div>
    </StyledDiscountCode>
  );
};

export default DiscountCode;

const StyledDiscountCode = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
`;

const StyledLabel = styled.label`
  color: #666666;
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
`;

const StyledInput = styled.input`
  height: 35px;
  border: 1px solid #adadad;
  background-color: #fff;
  border-radius: 1px;
  font-size: 12px;
  padding: 0 10px;

  &::placeholder {
    color: #c2c2c2;
    font-size: 12px;
  }
`;

const Button = styled.div`
  font-weight: 500;
  display: inline-block;
  text-align: center;
  border-radius: 0;
  height: 36px;
  margin-top: 20px;
  padding: 0 20px;
  line-height: 36px;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  color: white;
  background-color: ${PRIMARY};
  text-transform: uppercase;

  &:hover {
    background-color: #444444;
    color: white;
    cursor: pointer;
  }
`;
