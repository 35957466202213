import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import UnderlinedHeader from "../../molecules/UnderlinedHeader/UnderlinedHeader";
import styled from "styled-components";

const Youtube = () => {
  useEffect(() => {
    const vidDefer = document.getElementsByTagName("iframe");
    for (let i = 0; i < vidDefer.length; i++) {
      if (vidDefer[i].getAttribute("data-src")) {
        vidDefer[i].setAttribute("src", vidDefer[i].getAttribute("data-src"));
      }
    }
  }, []);

  return (
    <StyledWrapper>
      <Container>
        <StyledYoutube>
          <div>
            <lite-youtube
              videoId="BVBSDzjTnHw"
              params="controls=1"
              title={"Driving test: Pedders explain the effects of weight"}
              width="100%"
              height="315"
              allowFullScreen
            />
          </div>
          <div>
            <UnderlinedHeader
              text={"Jazdy testowe: Pedders wyjaśnia wpływ masy pojazdu"}
            />
            <p>
              Na międzynarodowym torze w Sandown, Pedders poddał testom serię
              pojazdów, aby podkreślić wpływ masy na prowadzenie, przyczepność i
              hamowanie.
            </p>
          </div>
        </StyledYoutube>
      </Container>
    </StyledWrapper>
  );
};

export default Youtube;

const StyledWrapper = styled.div`
  margin: 20px 0;
`;

const StyledYoutube = styled.div`
  display: grid;

  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  }
  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  }
`;
