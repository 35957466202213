import React, { useState } from "react";
import { withRouter } from "react-router";
import Group from "../../molecules/Group/Group";
import Category from "../../molecules/Category/Category";
import NowShoppingBy from "../../molecules/NowShoppingBy/NowShoppingBy";
import UnderlinedHeader from "../../molecules/UnderlinedHeader/UnderlinedHeader";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { groupDescriptionOrder } from "./../../../vars/orderLists";

const CategoriesSelector = ({ categoryTree, history }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const params = new URL(document.location).searchParams;

  const handleReset = () => {
    params.delete("groups");
    history.push({
      pathname: "/parts",
      search: params.toString(),
    });
  };

  const filterActiveCategory = (key) =>
    params.has("category") ? params.get("category") === key : true;

  const getGroupOrder = (groupName) => {
    const index = groupDescriptionOrder.findIndex(
      (item) => item.toLowerCase() === groupName.toLowerCase()
    );
    return index === -1 ? groupDescriptionOrder.length : index;
  };

  const tree = Object.keys(categoryTree)
    .filter(filterActiveCategory)
    .map((key) => (
      <Category name={key} key={key}>
        {Object.keys(categoryTree[key])
          .sort((a, b) => getGroupOrder(a) - getGroupOrder(b))
          .map((subkey) => (
            <Group
              key={subkey}
              name={subkey}
              quantity={categoryTree[key][subkey]}
            />
          ))}
      </Category>
    ));

  return (
    <div>
      <StyledSection>
        <HeaderWrapper onClick={() => setIsExpanded(!isExpanded)}>
          <UnderlinedHeader text={"Kategorie"} />
          <IconWrapper isExpanded={isExpanded}>
            <FontAwesomeIcon icon={faChevronDown} />
          </IconWrapper>
        </HeaderWrapper>

        <ContentWrapper isExpanded={isExpanded}>
          <NowShoppingBy
            category={params.get("category")}
            resetHandler={handleReset}
          />
          <Options>Podkategorie</Options>
          {tree}
        </ContentWrapper>
      </StyledSection>
    </div>
  );
};

export default withRouter(CategoriesSelector);

const StyledSection = styled.section`
  border: 1px solid #ddd;
  padding: 20px;
`;

const Options = styled.div`
  font-family: "Open Sans", sans-serif;
  margin-bottom: 10px;
  font-weight: bolder;
  font-size: 12px;
  color: #666666;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const IconWrapper = styled.span`
  color: #666666;
  font-size: 14px;
  transition: transform 0.3s ease;
  transform: rotate(${(props) => (props.isExpanded ? "180deg" : "0deg")});
`;

const ContentWrapper = styled.div`
  max-height: ${(props) => (props.isExpanded ? "2000px" : "0px")};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  margin-top: 15px;
`;
