import React from "react";
import styled from "styled-components";
import { vehicles } from "./vehicles";
import PopularVehicle from "../../molecules/PopularVehicle/PopularVehicle";
import Slider from "react-slick";

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 10000,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const PopularVehicles = ({ refProp }) => (
  <StyledPopularVehicles ref={refProp}>
    <Slider {...settings} lazyLoad="true">
      {vehicles.map((vehicle) => (
        <PopularVehicle {...vehicle} key={vehicle.name} />
      ))}
    </Slider>
  </StyledPopularVehicles>
);

export default PopularVehicles;

const StyledPopularVehicles = styled.section`
  margin: 10px 0 10px 10px;
`;
