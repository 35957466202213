const DICT = {
  AIRBAGS: "PODUSZKI POWIETRZNE",
  BRAKES: "HAMULCE",
  BUSHES: "TULEJE",
  DRIVELINE: "PRZENIESIENIE NAPĘDU",
  "SPRINGS AND SHOCKS": "SPRĘŻYNY, RESORY I AMORTYZATORY",
  STEERING: "UKŁAD KIEROWNICZY",
  "SUSPENSION KITS": "ZESTAWY ZAWIESZENIA",
  "SUSPENSION PARTS": "CZĘŚCI ZAWIESZENIA",
  OTHER: "INNE",
  "Accessories (S&S)": "Akcesoria (sprężyny i amortyzatory)",
  Airbags: "Poduszki powietrzne",
  "Air Tubing": "Przewód powietrzny",
  "Big Brakes": "Duże hamulce",
  "Brake Accessories": "Akcesoria hamulcowe",
  "Brake boosters": "Serwa hamulcowe",
  "Brake Conversion": "Konwersja hamulcowa",
  "Brake Discs": "Tarcze hamulcowe",
  "Brake Pads": "Klocki hamulcowe",
  "Brake Shoes & Drums": "Szczęki i bębny hamulcowe",
  Bumpstops: "Odboje",
  "Camber Bolts": "Śruby regulujące kąt",
  "Chassis Parts": "Części podwozia",
  "Control Arms": "Wahacze",
  "CV Shafts and Boots": "Półosie i osłony",
  Ezifits: "Kolumny Ezifit",
  "Extreme Xa Coilovers": "Zawieszenia gwintowane",
  "Front Shocks": "Przednie amortyzatory",
  "Front Spring Low": "Przednie sprężyny obniżające",
  "Front Spring Raised": "Przednie sprężyny podnoszące",
  "Front Spring Standard": "Przednie sprężyny standardowe",
  Kits: "Zestawy",
  KITS: "Zestawy",
  "Hubs & Swivel Kits": "Piasty i zwrotnice",
  "Links/Brackets/Shackles": "Łączniki/uchwyty/wieszaki",
  "Panhard Rods": "Drążki Panharda",
  "Poly Bushes": "Tuleje poliuretanowe",
  "Rear Shocks": "Tylne amortyzatory",
  "Rear Spring Low": "Tylne sprężyny obniżające",
  "Rear Spring Raised": "Tylne sprężyny podnoszące",
  "Rear Spring Standard": "Tylne sprężyny standardowe",
  "Rubber Bushes": "Tuleje gumowe",
  "Steering Dampers": "Amortyzatory skrętu",
  "Suspension Kit": "Zestaw zawieszenia",
  "Suspension Kits": "Zestawy zawieszenia",
  "Top Mounts": "Górne mocowania",
  "Trakryder Extreme Kits": "Zestawy Trakryder Extreme",
  "U-Bolts": "Strzemiona",
  undefined: "Results",
  "Inflation Valve": "Zawór powietrzny",
  "Rear Air Bag": "Tylne poduszki",
  "Rear Airbag": "Tylne poduszki",
  "Front Airbag": "Przednie poduszki zawieszenia",
  "Warranty Replacement Airbag": "Gwarancyjny zamiennik poduszki powietrznej",
  "High Pressure Sleeve Kits": "Zestawy rękawów wysokociśnieniowych",
  "Airbag Controller Kits": "Zestawy sterownika poduszki powietrznej",
  "Rear Brake Pads": "Tylne klocki hamulcowe",
  "Brake Upgrade Kits": "Zestawy modernizacyjne hamulców",
  "Drum to Disc Conversion Kits":
    "Zestawy do konwersji hamulca bębnowego na tarczę",
  "Brake Upgrade Kit Components": "Elementy zestawu modernizacyjnego hamulców",
  "Brake Upgrade Kit Spares":
    "Części zapasowe zestawu do modernizacji hamulców",
  "Brake Servo": "Serwo hamulcowe",
  "Front Brake Pads": "Przednie klocki hamulcowe",
  "Front Brake Rotors": "Przedni rotor tarczy hamulcowej",
  "Rear Brake Rotors": "Tylne rotory hamulcowe",
  "Rear Brake Shoes": "Tylne szczęki hamulcowe",
  "Rear Brake Pad Sensor": "Czujnik tylnego klocka hamulcowego",
  "Front Brake Pad Sensor": "Przedni sensor klocków hamulcowych",
  "Brake Fluid": "Płyn hamulcowy",
  "Park Brake Assembly": "Montaż hamulca postojowego",
  "Drum to Disc Conversion Spares":
    "Części zamienne do zestawu do konwersji hamulca bębnowego na tarczę",
  "Front Brake Discs": "Przednie tarcze hamulcowe",
  "Front Brake Upgrade Kit Components":
    "Elementy zestawu modernizacyjnego przedniego hamulca",
  "Pedders Drum to Disc Conversion Spares":
    "Części zamienne do zestawu do konwersji hamulca bębnowego na tarczę",
  "Rear Brake Drums": "Tylne bębny hamulcowe",
  "Radius / Brake Reaction Rod or Bushes": "Drążek lub tuleje reakcji hamulca",
  "Inner Cv Boot (Front)": "Osłona wewnętrznego przegubu (przód)",
  "CV Shaft Assembly (Front)": "Półosie (Przód)",
  "Steering Gear Couplings and  Mounts":
    "Sprzęgła i mocowania przekładni kierowniczej",
  "Outer Cv Boot (Front)": "Osłona zewnętrznego przegubu (przód)",
  "Power Steering Gear": "Przekładnia wspomagania kierownicy",
  "Drag Link": "Końcówki drążka kierowniczego",
  "CV Shaft Assembly (Rear)": "Półosie (Tył)",
  "Manual Steering Gear": "Ręczna przekładnia kierownicza",
  "Power Steering Pump": "Pompa wspomagania układu kierowniczego",
  Other: "Inne",
  Adjusters: "Regulatory",
  "Bump Stop": "Odboje",
  "Dust Boot": "Osłona przeciwpyłowa",
  "Multi Use Shock Fitting Kits":
    "Wielofunkcyjne zestawy do montażu amortyzatorów",
  "Rear Alignment Products": "Produkty do ustawienia tyłu",
  "Rear Anti-roll Bar Links": "Łączniki tylnego stabilizatora",
  "Outer Tie Rod End": "Zewnętrzna końcówka drążka kierowniczego",
  "Inner Tie Rod End": "Wewnętrzna końcówka drążka kierowniczego",
  "Lower Inner Bush": "Dolna tuleja wewnętrzna",
  "Front Bump Stop/Kit": "Przedni odbój (zestaw)",
  "Front Shock Mounts": "Mocowanie przedniego amortyzatora",
  "Front Anti-roll Bar Bushes": "Tuleje przedniego stabilizatora",
  "Front Anti-roll Bar Links": "Łączniki przedniego stabilizatora",
  "Engine Mounts": "Mocowania silnika",
  "Front Alignment Products": "Produkty do ustawienia przodu",
  "Front Lower Control Arm": "Przedni dolny wahacz",
  "Front Upper Control Arm": "Przedni górny wahacz",
  "Rear Lateral Arm Upper": "Górne tylne ramię boczne",
  "Rear Trailing Arm Lower": "Tylne wahacz wleczony dolny",
  "Upper Ball Joint": "Górny przegub kulowy",
  "Rear Toe Arm / Link": "Tylne ramię/łącznik",
  "Front Anti-roll Bar": "Przedni stabilizator",
  "Front Spring Insulator": "Izolator sprężyny przedniej",
  "Rear U-Bolts": "Tylne ubolty (cybanty)",
  "Lowering Blocks": "Bloki obniżające",
  "Rear Anti-roll Bar": "Tylny stabilizator",
  "Pitman Arm": "Ramię Pitmana",
  "Front Miscellaneous": "Front różne",
  "Rear Chassis/Body mounts": "Tylne mocowania podwozia/nadwozia",
  "Rear Trailing Arm Upper": "Górne ramię tylnego wahacza",
  "Extended Brake Hose Sets": "Przedłużone zestawy przewodów hamulcowych",
  "Leaf Spring Shackles": "Wieszaki amortyzatora piórowego",
  "Rear U Bolts": "Tylne ubolty (cybanty)",
  "Front Panhard Rod / Watts Link Bushes":
    "Tuleje przedniego drążka Panharda/łączników Watts",
  "Front U Bolts": "Przednie U-bolty (cybanty)",
  "Front Chassis/Body mounts": "Przednie mocowania podwozia/nadwozia",
  "Rear Ball Joints": "Przeguby kulowe tylne",
  "Front Hub": "Przednia piasta",
  "Relay Rod": "Pręt przekaźnikowy",
  "Rear Shock": "Tylny amortyzator",
  "Rear Shock Mounts": "Mocowanie tylnego amortyzatora",
  "Rear Miscellaneous": "Tył różne",
  "Front Undercar Protection": "Przednia ochrona podwozia",
  "Relay Rod End (Front)": "Końcówka drążka przekaźnika (przód)",
  "Tie Rod End Set": "Zestaw końcówek drążka kierowniczego",
  "Rear Lateral Arm Lower": "Tylne ramię boczne dolne",
  "Lower Ball Joint": "Dolny przegub kulowy",
  "Rear Bump Stop / Kit": "Odbój tylny (zestaw)",
  Coils: "Cewki",
  "Spring Seat": "Siedzisko sprężyny",
  "Rear Spring Insulator": "Izolator sprężyny tylnej",
  "Coil Over Kits [Full Car]": "Zestawy zawieszenia gwintowanego",
  "Rear Spring Raised (Variable Leaf pack)":
    "Tylna sprężyna podnosząca (pakiet zmiennych piór)",
  "Complete Strut Assembly (Front Raised)":
    "Kompletna kolumna amortyzatora (Podwyższony Przód)",
  "Complete Strut Assembly Frt Raised +Load":
    "Kompletna kolumna amortyzatora (Podwyższony Przód z obciążeniem)",
  "Complete Strut Assembly (Rear)": "Kompletna kolumna amortyzatora (Tył)",
  "Complete Strut Assembly (Front)": "Kompletna kolumna amortyzatora (Przód)",
  "Complete Strut Assembly (Front Standard)":
    "Kompletna kolumna amortyzatora (Standard Przód)",
  "Complete Strut Assembly (Front Lowered)":
    "Kompletna kolumna amortyzatora (Obniżony Przód)",
  "Front Shock": "Przedni amortyzator",
  "Coil Over Kit [Pair] Multiple Fit":
    "Zestawy zawieszenia gwintowanego wielokrotnego dopasowania",
  "Spare parts (shocks)": "Części zamienne (amortyzatory)",
  "Front Spring Standard Height": "Przednia sprężyna standardowej wysokości",
  "Rear Spring Standard Height": "Tylna sprężyna standardowej wysokości",
  "Complete Strut Assembly (Rear Raised)":
    "Kompletna kolumna amortyzatora (Podwyższony Tył)",
  "Coilover Kits": "Zestawy zawieszenia gwintowanego",
  "Rea spring standard height": "Tylna sprężyna standardowej wysokości",
  "Multi Use Shock Bushes": "Wielofunkcyjne tuleje amortyzatorów",
  "Upper Inner Bush": "Górna tuleja wewnętrzna",
  "Rear Anti-roll Bar Bushes": "Tuleje tylnego stabilizatora",
  "Rear Shackle Bushes / Kits": "Tuleje tylnego wieszaka (zestawy)",
  "Rear Crossmember Bushes": "Tuleje tylnej poprzeczki",
  "Rear Panhard Rod / Watts Link Bushes":
    "Tuleje tylnego drążka Panharda/łączników Watts",
  "Rear Fixed Eye Bushes / Kits": "Tylne tuleje oczkowe stałe (zestawy)",
  "Rear Shackle Upper Bushes": "Górne tuleje tylnego wieszaka",
  "Idler Arm &/or Bush Kit": "Zestaw ramienia koła napinającego i/lub tulei",
  "Rear Shackle Lower Bushes": "Dolne tuleje tylnego wieszaka",
  "Front Fixed Eye Bushes / Kits": "Przednie tuleje z oczkiem stałym (zestaw)",
  "R Of R Shackle Bushes / Kit": "Tuleja dolnego łącznika resora piórowego",
  "R of F Shackle Bushes / Kit": "Tuleja dolnego łącznika resora piórowego",
  Shackles: "Wieszaki",
  "Front Leading / Trailing Arm Bushes":
    "Tuleje wahaczy przednich/wahaczy wleczonych",
  "Front Spring Bushes / Kits": "Tuleje sprężyn przednich (zestaw)",
  "Front Shackle Lower Bushes": "Tuleje przedniego dolnego wieszaka",
  "Front Shackle Upper Bushes": "Tuleje przedniego górnego wieszaka",
  "Front Shackle Bushes / Kits": "Tuleje wieszaka przedniego (zestaw)",
  "Rear Spring All Bushes / Kits": "Tuleje tylnego resora (zestawy)",
  "F of F Shackle Bushes / Kit": "Górne tuleje przedniego wieszaka",
  "Air Bag Controllers": "Sterowniki poduszek powietrznych",
  "Chassis Geometry Correction Kits": "Zestawy do korekcji geometrii podwozia",
  "F Of R Shackle Bushes/Kit": "Dolne tuleje tylnego wieszaka",
  "Inner Cv Boot (Rear)": "Osłona wewnętrznego przegubu (tył)",
  "Outer Cv Boot (Rear)": "Osłona zewnętrznego przegubu (tył)",
  "Power Steering Hose": "Wąż układu wspomagania kierownicy",
  "Rear Brake Hoses": "Przewody hamulcowe tylne",
  "Rear Shackle Lower Bushes/Kits": "Dolne tuleje tylnego wieszaka (zestawy)",
  "Relay Rod End (Rear)": "Końcówka drążka przekaźnika (tył)",
  "Centre Bearing Drop Kit": "Zestaw łożysk środkowych",
  Steering: "Sterowanie",
};

export default DICT;
