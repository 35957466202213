import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router";
import layoutContext from "../../../context/LayoutContext";
import { Container } from "react-bootstrap";
import UnderlinedHeader from "../../molecules/UnderlinedHeader/UnderlinedHeader";
import { search } from "../../../axios/api";
import NoResults from "../../molecules/NoResults/NoResults";
import SearchResults from "../../organisms/SearchResults/SearchResults";
import useBasketActions from "../../../hooks/useBasketActions";
import Loader from "../../molecules/Loader/Loader";
import { useAppContext } from "../../../context/AppContext";

const Search = () => {
  const params = useParams();
  const layout = useContext(layoutContext);
  const { userCountry } = useAppContext();
  const [parts, setParts] = useState([]);
  const [showLoader, setShowLoader] = useState(true);

  const { addHandler, buttonText } = useBasketActions();

  useEffect(() => {
    setShowLoader(true);
    layout.setSmallNavText(`Search results for: "${params.term}"`);
    if (userCountry.code) {
      search(params.term, userCountry.code)
        .then(map3CData)
        .then(assignData)
        .finally(() => setShowLoader(false));
    }
  }, [params.term, userCountry]);

  return (
    <Container>
      <Layout>
        <div />
        <div>
          <UnderlinedHeader
            bolder
            text={`Search results for: "${params.term}"`}
          />
          {showLoader ? (
            <Loader show={true} />
          ) : parts.length ? (
            <SearchResults
              items={parts}
              addToCart={addHandler}
              buttonText={buttonText}
            />
          ) : (
            <NoResults />
          )}
        </div>
      </Layout>
    </Container>
  );

  function map3CData(res) {
    const itemsFrom3C = res.data.map((i) => ({
      ...i,
      item: i,
    }));
    return { itemsFrom3C };
  }

  function assignData({ itemsFrom3C }) {
    return setParts(itemsFrom3C);
  }
};

export default Search;

const Layout = styled.div`
  padding-top: 30px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  column-gap: 24px;
`;
