import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { PRIMARY } from "../../../vars/vars";

const PopularVehicle = ({ name, imageWebp, to }) => (
  <StyledPopularVehicle>
    <StyledImage alt={name} src={imageWebp} />
    <Overlay to={to} aria-label={name + " link informacyjny"} />
    <StyledButton to={to}>{name}</StyledButton>
  </StyledPopularVehicle>
);

export default PopularVehicle;

const StyledPopularVehicle = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  margin-right: 10px;
`;

const StyledImage = styled.img`
  width: 100%;
`;

const Overlay = styled(Link)`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background-color: white;

  &:hover {
    opacity: 0.2;
    cursor: pointer;
  }
`;

const StyledButton = styled(Link)`
  background-color: #fff;
  position: absolute;
  bottom: 20px;
  color: #333;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 8px 30px;
  height: auto;

  &:hover {
    cursor: pointer;
    background-color: ${PRIMARY};
    color: white;
  }
`;
