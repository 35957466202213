import React from "react";
import { Alert } from "react-bootstrap";

const NoResults = () => (
  <Alert variant="warning">
    Nie można odnaleźć pasujących produktów.
  </Alert>
);

export default NoResults;
