import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { PRIMARY } from "../../../vars/vars";
import SummaryDropdown from "../../molecules/SummaryDropdown/SummaryDropdown";
import DiscountCode from "../../molecules/DiscountCode/DiscountCode";
import EstimateDelivery from "../../molecules/EstimateDelivery/EstimateDelivery";
import { Spinner } from "react-bootstrap";
import { useAppContext } from "../../../context/AppContext";

const BasketSummary = ({
  country,
  countryHandler,
  isLoading,
  radioValue,
  radioChangeHandler,
}) => {
  const { basket, summary } = useAppContext();

  return (
    <StyledBasketSummary>
      <Header>Podsumowanie</Header>
      <SummaryDropdown text={"Szacowany koszt dostawy"}>
        <EstimateDelivery
          changeHandler={countryHandler}
          value={country}
          radioChangeHandler={radioChangeHandler}
          radioValue={radioValue}
        />
      </SummaryDropdown>
      {basket && (
        <Details>
          <Detail>
            <div>Suma częściowa</div>
            <div>
              {summary.itemsamount} {basket.currency.symbol}
            </div>
          </Detail>
          <Detail>
            <div>Koszt dostawy</div>
            <div>
              {summary.shippingcost} {basket.currency.symbol}
            </div>
          </Detail>
          <Detail>
            <div>Podatek</div>
            <div>
              {summary.taxamount} {basket.currency.symbol}
            </div>
          </Detail>
          <Detail>
            <div>W sumie</div>
            <div>
              {summary.grossamount} {basket.currency.symbol}
            </div>
            {basket.transactionlines[0].discountrate ? (
              <DiscountInfo>
                <p>
                  Zawiera{" "}
                  {Math.abs(Number(basket.transactionlines[0].discountrate)) *
                    100}
                  % rabatu{" "}
                </p>
              </DiscountInfo>
            ) : null}
          </Detail>
        </Details>
      )}
      <SummaryDropdown text={"Dodaj kod zniżkowy"}>
        <DiscountCode />
      </SummaryDropdown>
      <Buttons>
        <Button
          to={"/checkout"}
          disabled={isLoading}
          className="gtm_btn_checkout"
        >
          {isLoading ? <Spinner animation="border" size={"sm"} /> : "Zamów"}
        </Button>
      </Buttons>
    </StyledBasketSummary>
  );
};

export default BasketSummary;

const StyledBasketSummary = styled.section`
  align-self: flex-start;
  grid-area: summary;
  padding: 25px 25px 0 25px;
  border: 1px solid #ddd;
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;

  @media (min-width: 1000px) {
    position: sticky;
    top: 10px;
  }
`;

const Header = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  color: #666666;
  font-size: 18px;
  margin-bottom: 10px;
`;

const Buttons = styled.div`
  background-color: #fafafa;
  margin: 20px -25px 0 -25px;
  padding: 25px;
  border-top: 1px solid #dee2e6;
`;

const Button = styled(Link)`
  display: block;
  font-weight: 500;
  border-radius: 0;
  height: 36px;
  line-height: 36px;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  color: white;
  background-color: ${PRIMARY};
  text-transform: uppercase;
  text-align: center;

  &:hover {
    background-color: #444444;
    color: white;
  }
`;

const Details = styled.div`
  margin-top: 10px;
`;

const Detail = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  color: #666666;
  justify-content: space-between;
  padding: 8px;
  font-weight: 400;
  border-top: 1px solid #dee2e6;
`;

const DiscountInfo = styled.div`
  width: 100%;
  color: red;
  text-align: right;
  font-weight: 700;
`;
