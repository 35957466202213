import React, { useRef } from "react";
import Container from "react-bootstrap/Container";
import PartFinder from "../../organisms/PartFinder/PartFinder";
import styled from "styled-components";
import { PRIMARY } from "../../../vars/vars";
import "./Home.scss";
import PopularVehicles from "../../organisms/PopularVehicles/PopularVehicles";
import Youtube from "../../organisms/Youtube/Youtube";
import CarouselRibbon from "../../organisms/CarouselRibbon/CarouselRibbon";

const HomeComponent = () => {
  const vehiclesRef = useRef(null);

  const scrollToVehicles = () => {
    vehiclesRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <Section>
      <Container className="carosuelRibbon">
        <CarouselRibbon />
      </Container>
      <StyledPicture className={"Home"}>
        <p className={"header"}>
          Wiodący specjalista w dziedzinie zawieszeń samochodowych i układów
          hamulcowych
        </p>
        <div onClick={scrollToVehicles} className={"popular"}>
          Zobacz nasze najbardziej popularne auta
        </div>
      </StyledPicture>
      <PartFinderContainer className="partFinder">
        <PartFinder />
      </PartFinderContainer>
      <PopularVehicles refProp={vehiclesRef} />
      <Youtube />
    </Section>
  );
};

export default HomeComponent;

const Section = styled.section`
  display: flex;
  flex-direction: column;

  .carosuelRibbon {
    margin: 20px auto;
  }

  @media (min-width: 1000px) {
    .carosuelRibbon {
      margin: 0 auto;
      order: -3;
    }

    .Home {
      order: -2;
    }

    .partFinder {
      order: -1;
    }
  }
`;

const PartFinderContainer = styled.div`
  order: -2;
  margin: 20px 10px;
  padding: 0;
`;

const StyledPicture = styled.div`
  order: -1;
  height: 450px;
  margin: 20px 10px;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;

  .header {
    font-size: 25px;
    font-weight: bold;
    line-height: 1.2;
    color: white;
    width: 100%;
    text-align: center;
    padding: 10px;

    @media screen and (min-width: 794px) {
      width: 50%;
      font-size: 40px;
      padding: 0;
    }
  }

  .popular {
    padding: 10px;
    font-size: 13px;
    background-color: ${PRIMARY};
    color: white;

    @media screen and (min-width: 794px) {
      padding: 10px 20px;
      position: absolute;
      font-size: 23px;
      bottom: -10px;
    }

    &:hover {
      cursor: pointer;
    }

    &:before,
    &:after {
      content: "\\f107";
      display: inline-block;
      font-family: FontAwesome, sans-serif;
      text-align: center;
      font-variant: normal;

      @media screen and (min-width: 794px) {
        font-size: 20px;
      }
    }

    &:before {
      margin-right: 10px;
    }

    &:after {
      margin-left: 10px;
    }
  }
`;
