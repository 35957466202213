import React from "react";
import styled from "styled-components";
import SummaryHeader from "../../atoms/SummaryHeader/SummaryHeader";
import { useAppContext } from "../../../context/AppContext";

const DeliverySummary = ({ active }) => {
  const { address, basket, basketOptions } = useAppContext();
  const shippingMethod =
    basketOptions &&
    basketOptions.shippingmethod.find((m) => m._id === basket.shippingmethod);
  const paymentMethod =
    basketOptions &&
    basketOptions.paymentmethod.find((m) => m._id === address.payment);

  return (
    <StyledComponent>
      {address && (
        <>
          {" "}
          <SummaryHeader
            text={"Adres dostawy"}
            cog={"/checkout"}
            active={active}
          />
          <p>{address.company}</p>
          <p>
            {address.firstName} {address.lastName}
          </p>
          <p>
            {address.streetAddress} {address.streetAddress2}{" "}
            {address.streetAddress3}
          </p>
          <p>
            {address.city}, {address.zip}
          </p>
          <p>{address.country}</p>
          <p>{address.phone}</p>
          <p className={"mt-2"}>
            {shippingMethod.displayname || shippingMethod.name || ""}
          </p>
          <p className={"mt-2"}>
            {paymentMethod.displayname || paymentMethod.name || ""}
          </p>
        </>
      )}
    </StyledComponent>
  );
};

export default DeliverySummary;

const StyledComponent = styled.section`
  margin-top: 30px;
  padding: 30px;
  background-color: #fafafa;
  font-size: 12px;
  color: #666666;
  font-family: "Open Sans", sans-serif;

  p {
    margin-bottom: 5px;

    &:first-of-type {
      margin-top: 20px;
    }
  }
`;
