import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { PulseLoader } from "react-spinners";

const CatalogueLoader = ({ show }) => {
  const [message, setMessage] = useState("Ładuję...");

  useEffect(() => {
    if (!show) return;

    const messages = [
      "Ładuję katolog...",
      "Pobieram produkty...",
      "Prawie gotowe...",
    ];

    const interval = setInterval(() => {
      setMessage(messages[Math.floor(Math.random() * messages.length)]);
    }, 2000);

    return () => clearInterval(interval);
  }, [show]);

  if (!show) return null;

  return (
    <LoaderWrapper>
      <PulseLoader color="#DC3332" size={10} />
      <LoadingText>{message}</LoadingText>
    </LoaderWrapper>
  );
};

export default CatalogueLoader;

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
`;

const LoadingText = styled.div`
  color: #666;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
`;
