import React from "react";
import styled from "styled-components";
import UnderlinedHeader from "../UnderlinedHeader/UnderlinedHeader";
import FooterLink from "../../atoms/FooterLink/FooterLink";
import { BREAKPOINT, PRIMARY, SECONDARY } from "../../../vars/vars";
import { useAppContext } from "../../../context/AppContext";

const FooterGroup = ({ header, links, special }) => {
  const { setShowCookiesManager } = useAppContext();

  const handleClick = () => {
    setShowCookiesManager(true);
  };

  return (
    <StyledFooterGroup special={special}>
      <UnderlinedHeader text={header} />
      {links.map(({ to, display, direct, specialKits }) =>
        display === 'Zarządzaj "Cookies"' ? (
          <StyledLink display={display} key={display} onClick={handleClick}>
            <span>{display}</span>
          </StyledLink>
        ) : (
          <FooterLink
            key={display}
            to={to}
            display={display}
            direct={direct}
            specialKits={specialKits}
          />
        )
      )}
    </StyledFooterGroup>
  );
};

export default FooterGroup;

const StyledFooterGroup = styled.div`
  padding: 20px 0;
  background-color: ${(props) => (props.special ? "#f9f9f9" : null)};

  @media screen and (min-width: ${BREAKPOINT}) {
    grid-column: ${(props) => (props.special ? "1 / 4" : null)};
    padding: 70px 30px 60px;
  }

  @media screen and (min-width: 992px) {
    grid-column: auto;
  }
`;
const StyledLink = styled.div`
  color: ${SECONDARY};
  font-weight: 400;
  display: flex;
  align-items: flex-end;
  font-size: 13px;
  height: 24px;
  margin: 5px 0;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;

  &:hover {
    color: ${PRIMARY};
  }
  &:hover span {
    text-decoration: underline;
  }

  &:before {
    content: "\f101";
    font-family: "FontAwesome", sans-serif;
    display: inline-block;
    margin-right: 5px;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
`;
