import React from "react";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck } from "@fortawesome/free-solid-svg-icons/faTruck";
import { deliveryTime } from "./../../../vars/vars";

const isDateDifferenceGreaterThan = (
  deliveryObj,
  days,
  delayDaysInDelivery
) => {
  const currentDate = new Date();
  const deliveryDate = new Date(deliveryObj?.eta || deliveryObj);

  const differenceInMilliseconds = deliveryDate - currentDate;
  const differenceInDays = Math.ceil(
    differenceInMilliseconds / (24 * 60 * 60 * 1000)
  );

  const availableInDays = differenceInDays;
  const availableInDaysWithDelay = availableInDays + delayDaysInDelivery;

  return {
    check: differenceInDays <= days,
    info: `Produkt w trakcie transportu - dostawa w ciągu ${availableInDays}-${availableInDaysWithDelay} dni`,
  };
};

const DeliveryInfoDetails = ({ quantity, availability }) => {
  const getMessage = () => {
    if (!availability) return null;

    const {
      locationQty,
      manufacturerQty,
      transit: {
        productsInTransitInLessTreeWeeks,
        productsInTransitInOverTreeWeeks,
      },
    } = availability;

    const totalLessThanThreeWeeks =
      locationQty + productsInTransitInLessTreeWeeks;
    const totalLessThanThreeWeeksAndManufacturer =
      totalLessThanThreeWeeks + manufacturerQty;
    const totalAllProducts =
      totalLessThanThreeWeeksAndManufacturer + productsInTransitInOverTreeWeeks;

    if (quantity <= locationQty)
      return (
        <Delivery>
          <FontAwesomeIcon size={"sm"} icon={faTruck} color="green" />
          <p style={{ margin: "0 0 0 10px" }}>{deliveryTime.IN_STOCK}*</p>
        </Delivery>
      );
    if (quantity <= totalLessThanThreeWeeks)
      return (
        <Delivery>
          <FontAwesomeIcon size={"sm"} icon={faTruck} color="orange" />
          <p style={{ margin: "0 0 0 10px" }}>{deliveryTime.IN_TRANSIT}</p>
        </Delivery>
      );
    if (
      quantity <= totalLessThanThreeWeeksAndManufacturer &&
      quantity > totalLessThanThreeWeeks
    )
      return (
        <Delivery>
          <FontAwesomeIcon size={"sm"} icon={faTruck} color="orange" />
          <p style={{ margin: "0 0 0 10px" }}>{deliveryTime.IN_MANUFACTURER}</p>
        </Delivery>
      );
    if (
      quantity <= totalAllProducts &&
      quantity > totalLessThanThreeWeeksAndManufacturer
    )
      return (
        <Delivery>
          <FontAwesomeIcon size={"sm"} icon={faTruck} color="orange" />
          <p style={{ margin: "0 0 0 10px" }}>
            {
              isDateDifferenceGreaterThan(
                availability.transit.deliveryDateOverThreeWeeks,
                20,
                7
              ).info
            }
          </p>
        </Delivery>
      );

    return null;
  };

  return <div>{getMessage()}</div>;
};

export default DeliveryInfoDetails;

const Delivery = styled.div`
  display: flex;
  font-size: 13px;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 600;
`;
