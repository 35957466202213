export const countries = [
  { name: "Austria", iso: "AT" },
  { name: "Belgia", iso: "BE" },
  { name: "Bułgaria", iso: "BG" },
  { name: "Chorwacja", iso: "HR" },
  { name: "Cypr", iso: "CY" },
  { name: "Republika Czeska", iso: "CZ" },
  { name: "Dania", iso: "DK" },
  { name: "Estonia", iso: "EE" },
  { name: "Finlandia", iso: "FI" },
  { name: "Niemcy", iso: "DE" },
  { name: "Grecja", iso: "GR" },
  { name: "Węgry", iso: "HU" },
  { name: "Irlandia", iso: "IE" },
  { name: "Włochy", iso: "IT" },
  { name: "Kazachstan", iso: "KZ" },
  { name: "Łotwa", iso: "LV" },
  { name: "Litwa", iso: "LT" },
  { name: "Luksemburg", iso: "LU" },
  { name: "Malta", iso: "MT" },
  { name: "Holandia", iso: "NL" },
  { name: "Norwegia", iso: "NO" },
  { name: "Polska", iso: "PL" },
  { name: "Portugalia", iso: "PT" },
  { name: "Rumunia", iso: "RO" },
  { name: "Słowacja", iso: "SK" },
  { name: "Słowenia", iso: "SI" },
  { name: "Hiszpania", iso: "ES" },
  { name: "Szwecja", iso: "SE" },
  { name: "Szwajcaria", iso: "CH" },
];
