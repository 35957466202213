import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";

const BottomBar = () => (
  <StyledBottomBar>
    <Container>
      Pedders Suspension &copy; 2020 Wszelkie prawa zastrzeżone.
    </Container>
  </StyledBottomBar>
);

export default BottomBar;

const StyledBottomBar = styled.section`
  background-color: #f5f5f5;
  padding: 30px 0;
  color: #555555;
  font-weight: 400;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
`;
