import React, { Suspense, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { withRouter, useLocation } from "react-router-dom";
import routes from "../../../routes/routes";
import Topbar from "../../organisms/Topbar/Topbar";
import Navbar from "../../organisms/Navbar/Navbar";
import SmallNavigation from "../../organisms/SmallNavigation/SmallNavigation";
import Footer from "../../organisms/Footer/Footer";
import BottomBar from "../../organisms/BottomBar/BottomBar";
import RouterView from "../../organisms/RouterView/RouterView";
import CarouselRibbon from "../../organisms/CarouselRibbon/CarouselRibbon";
import Marquee from "../../molecules/Marquee/Marquee";
import { useAppContext } from "../../../context/AppContext";
import { initiateCheckoutCAPI, pageViewCAPI } from "../../../utils/capi";

const includeSmallNavigation = [
  "parts",
  "details",
  "search",
  "features",
  "distributors",
  "info",
  "faq",
];

const MainLayout = ({ location }) => {
  const { userCountry, basket } = useAppContext();
  const locations = useLocation();

  useEffect(() => {
    if (userCountry?.code) {
      pageViewCAPI(userCountry.code || "PL");

      if (window.location.pathname.includes("/checkout")) {
        initiateCheckoutCAPI(basket);
      }
    }
  }, [location]);

  return (
    <>
      <Topbar />
      <Marquee />
      <Navbar />
      <Container>
        {locations.pathname !== "/" ? <CarouselRibbon /> : null}
      </Container>
      {includeSmallNavigation.some((item) =>
        location.pathname.includes(item)
      ) && <SmallNavigation />}
      <Suspense fallback={null}>
        <RouterView routes={routes} />
      </Suspense>
      <Footer />
      <BottomBar />
    </>
  );
};

export default withRouter(MainLayout);
