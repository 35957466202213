import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useAppContext } from "../../../context/AppContext";

import OrderKitComponent from "../OrderKitComponent/OrderKitComponent";
import { getItemData } from "../../../axios/api";
import { PRIMARY } from "../../../vars/vars";
import placeholderWebp from "../../../assets/placeholders/pedders_placeholder.webp";
import placeholder from "../../../assets/placeholders/pedders_placeholder.jpg";

const OrderKit = ({ item, currency }) => {
  const [showComponents, setShowComponents] = useState(false);
  const { userCountry } = useAppContext();
  const [part, setPart] = useState(null);

  useEffect(() => {
    async function fetch(item, userCountry) {
      const [{ data: part }] = await getItemData(
        item.item.urlcomponent,
        userCountry.code
      );

      setPart(part);
    }
    fetch(item, userCountry);
  }, []);

  return (
    <>
      <DescriptionWrapper>
        <Photo to={`/details/${item.item.urlcomponent}`}>
          {part && part.photos.length ? (
            <img src={part.photos[0].url} alt={part.photos[0].url} />
          ) : (
            <picture>
              <source srcSet={placeholderWebp} type="image/webp" />
              <img src={placeholder} alt={"item.displayname"} />
            </picture>
          )}
        </Photo>
        <div>
          <Description to={`/details/${item.item.urlcomponent}`}>
            {item.item.description}
            <br />
            {item.displayname}
          </Description>
        </div>
      </DescriptionWrapper>
      <Price>
        <span>Price:</span>
        {item.grossprice || item.price} {currency}
      </Price>

      <Price>{item.quantity}</Price>

      <Price>
        <span>Subtotal:</span>
        {item.amount || item.grosamount} {currency}
      </Price>
      {
        <Buttons
          style={{
            borderBottom: showComponents ? "none" : "1px solid #dee2e6",
          }}
        >
          <Button onClick={() => setShowComponents(!showComponents)}>
            {" "}
            {showComponents ? "Ukryj komponenty" : "Pokaż komponenty"}
          </Button>
        </Buttons>
      }
      {showComponents && (
        <ComponentsContainer>
          {part.components.map((item) => {
            return <OrderKitComponent key={item._id} item={item} />;
          })}
        </ComponentsContainer>
      )}
    </>
  );
};

export default OrderKit;

const ComponentsContainer = styled.div`
  grid-column: 1 / 5;
  padding: 0 20px 20px;
  display: grid;
  flex-direction: column;
  border-bottom: 1px solid #dee2e6;

  @media (min-width: 768px) {
    padding: 0 40px 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  }
`;

const Button = styled.button`
  border: 1px solid #cdcdcd;
  background-color: white;
  color: #333;
  min-width: 100px;
  font-size: 12px;
  padding: 7px;

  &:hover {
    color: white;
    background-color: ${PRIMARY};
  }
`;

const Buttons = styled.div`
  grid-column: 1 / 5;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  padding: 8px 8px 20px 8px;

  @media (min-width: 767px) {
    padding: 8px 8px 20px 8px;
    margin-top: 0px;
  }
`;

const Price = styled.div`
  grid-column: 1 / 5;
  display: flex;
  justify-content: space-between;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: #666666;
  font-size: 16px;
  vertical-align: top;
  padding: 10px 8px 0;
  text-align: right;

  @media (min-width: 767px) {
    padding: 10px 0px 0;
    grid-column: auto;
    display: block;
    font-size: 17px;

    span {
      display: none;
    }
  }
`;

const Description = styled(Link)`
  flex-basis: 60%;
  display: inline-block;
  font-size: 14px;
  width: 100%;
  text-align: center;
  color: #444444;
  margin-bottom: 16px;
  font-family: "Open Sans", sans-serif;
  &:hover {
    color: ${PRIMARY};
  }

  @media (min-width: 767px) {
    text-align: left;
    font-size: 15px;
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1 / 5;
  gap: 5px;
  font-size: 12px;
  padding: 10px 0;

  @media (min-width: 767px) {
    flex-direction: row;
    grid-column: auto;
  }
`;

const Photo = styled(Link)`
  img {
    width: 100%;
    height: 150px;
    object-fit: contain;
    border: 1px solid #ddd;
    &:hover {
      border-color: ${PRIMARY};
    }
  }

  @media (min-width: 767px) {
    img {
      width: 150px;
      margin-right: 10px;
    }
  }
`;
