import React from "react";
import "./MniBasket.scss";
import MiniBasketHeader from "../../atoms/MiniBasketHeader/MiniBasketHeader";
import MiniBasketButtons from "../../molecules/MiniBasketButtons/MiniBasketButtons";
import MiniBasketSubtotal from "../../atoms/MiniBasketSubtotal/MiniBasketSubtotal";
import MiniBasketItem from "../../molecules/MiniBasketItem/MiniBasketItem";
import useBasketActions from "../../../hooks/useBasketActions";
import CountryAlert from "../CountryAlert/CountryAlert";
import { useAppContext } from "../../../context/AppContext";

const MiniBasket = ({ showMiniBasket, setShowMiniBasket }) => {
  const { basket, summary } = useAppContext();
  const { updateHandler, deleteHandler } = useBasketActions();

  const Empty = () => (
    <div className={"MiniBasket MiniBasketEmpty"}>Koszyk jest pusty.</div>
  );

  const Full = () => (
    <div className={"MiniBasket MiniBasketFull"}>
      <MiniBasketHeader />
      {basket &&
        basket.transactionlines
          .filter((item) => item.kitcomponent !== true)
          .map((item) => (
            <MiniBasketItem
              deleteHandler={deleteHandler}
              changeHanlder={updateHandler}
              key={item._id}
              item={item}
              currency={basket.currency.symbol}
            />
          ))}
      <MiniBasketSubtotal
        subtotal={summary.grossitemsamount}
        currency={basket.currency.symbol}
      />
      <MiniBasketButtons closeMiniBasket={() => setShowMiniBasket(false)} />
      <CountryAlert />
    </div>
  );

  return (
    <div>
      {showMiniBasket ? (
        basket && basket.transactionlines.length ? (
          <Full />
        ) : (
          <Empty />
        )
      ) : null}
    </div>
  );
};

export default MiniBasket;
