import React from "react";
import styles from "./NowShoppingBy.module.scss";
import styled from "styled-components";
import DICT from "../../../vars/dictionary";

const NowShoppingBy = ({ category, resetHandler }) => (
  <div className={styles.NowShoppingBy}>
    <div className={styles.Header}>Teraz przeglądasz</div>
    <div>
      <span className={styles.Subheader}>Kategoria:</span> {DICT[category]}
    </div>
    <Actions>
      <button className={styles.Button} onClick={resetHandler}>
        Wyczyść
      </button>
    </Actions>
  </div>
);

export default NowShoppingBy;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;
