/*global gtag*/
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import useBasketActions from "../../../hooks/useBasketActions";
import { useParams } from "react-router";
import DetailsAlert from "../../molecules/DetailsAlert/DetailsAlert";
import OrderCart from "../../organisms/OrderCart/OrderCart";
import OrderPageSummary from "../../organisms/OrderPageSummary/OrderPageSummary";
import OrderPageDelivery from "../../organisms/OrderPageDelivery/OrderPageDelivery";
import { PAYMENT_METHODS, WIDGET_TYPE } from "../../../vars/vars";
import UniversalModal from "../../organisms/UniversalModal/UniversalModal";
import { getErrorText, createPayPalScript } from "../../../vars/functions";
import axios from "axios";
import RevolutCheckout from "@revolut/checkout";
import revolutLogo from "./../../../assets/revolut.png";
import { setPaymentStatus, getPaymentMethods } from "../../../axios/api";
import PaymentStatus from "../../molecules/PaymentStatus/PaymentStatus";
import { purchaseCAPI } from "../../../utils/capi";

const Order = () => {
  const { get3COrder } = useBasketActions();
  const { id } = useParams();
  const [order3C, setOrder3C] = useState(null);
  const [error, setError] = useState("");
  const [status, setStatus] = useState("");

  const modalClickHandler = () => setError("");

  const getRevolutToken = async (order) => {
    const res = await getPaymentMethods({
      currency: order?.currency?.iso,
      amount: order?.grossamount * 100,
      transactionid: order?._id,
    });

    const revolutMethod = res.find(
      (method) => method.widgettype === WIDGET_TYPE.REVOLUT
    );

    return revolutMethod?.token[0]?.service?.token || "";
  };

  const handleRevolutPayment = async () => {
    const token = await getRevolutToken(order3C);

    try {
      const instance = await RevolutCheckout(token);

      instance.payWithPopup({
        name: order3C.shipaddressee,
        onSuccess: () => {
          setStatus("COMPLETED");
          setPaymentStatus(id);
        },
        onError(message) {
          alert(message);
        },
      });
    } catch (error) {
      setError(getErrorText(error));
    }
  };

  const getPayPalToken = async (order) => {
    const res = await getPaymentMethods({
      currency: order?.currency?.iso,
      amount: order?.grossamount,
      transactionid: order?._id,
    });

    const payPalMethod = res.find(
      (method) => method.widgettype === WIDGET_TYPE.PAYPAL
    );

    return payPalMethod?.token[0]?.value || "";
  };

  const renderPayPal = (grossamount, cartId) => {
    const order = {
      purchase_units: [
        {
          amount: {
            value: grossamount,
            currency_code: "PLN",
          },
        },
      ],
    };

    window.paypal
      .Buttons({
        createOrder: (data, actions) => actions.order.create(order),
        onApprove: (data, actions) => {
          return actions.order.capture().then((details) => {
            setStatus(details.status);
            if (details.status === "COMPLETED") {
              setPaymentStatus(id);
            }
          });
        },
      })
      .render("#pp");
  };

  useEffect(() => {
    const initializePayment = async () => {
      try {
        const { data } = await get3COrder(id);
        setOrder3C(data);

        const token = await getPayPalToken(data);

        createPayPalScript(token, data.currency.iso, () => {
          try {
            renderPayPal(data.grossamount, id);

            window.dataLayer.push({
              event: "purchase",
              ecommerce: {
                items: data.transactionlines.map((line) => ({
                  id: line._id,
                  name: line.displayname,
                  quantity: line.quantity,
                  price: line.grosamount,
                })),
                transaction_id: data.name,
                value: data.grossamount,
                tax: data.taxamount,
                shipping: data.grossshippingcost,
                currency: "PLN",
                coupon: "",
              },
            });
            purchaseCAPI(data);
          } catch (err) {
            setError(getErrorText(err));
          }
        });
      } catch (error) {
        console.error("Payment initialization failed:", error);
        setError(getErrorText(error));
      }
    };

    initializePayment();
  }, [id]);

  const StatusInfo = () => {
    if (status) {
      return status === "COMPLETED" ? (
        <DetailsAlert paid />
      ) : (
        <DetailsAlert other={status} />
      );
    }
    return null;
  };

  return (
    <Container>
      {order3C && order3C.paymentstatus && (
        <PaymentStatus orderName={order3C.name} />
      )}
      <OrderWrapper>
        <OrderContainer>
          {/* <OrderProgress /> */}
          <StatusInfo />
          {order3C && (
            <OrderCart
              currency={order3C.currency.symbol}
              lines={order3C.transactionlines}
            />
          )}
          {order3C &&
            order3C.paymentmethod &&
            order3C.paymentmethod._id === PAYMENT_METHODS.PAYPAL &&
            order3C.status._id === "pendingapproval" &&
            order3C.status._id !== "closed" &&
            !order3C.paymentstatus && (
              <Paypal>
                <SectionTitle>Zapłać za pomocą Paypal'a</SectionTitle>
                <div id={"pp"} />
              </Paypal>
            )}
          {order3C &&
            order3C.paymentmethod &&
            order3C.paymentmethod._id === PAYMENT_METHODS.REVOLUT &&
            order3C.status._id === "pendingapproval" &&
            order3C.status._id !== "closed" &&
            !order3C.paymentstatus && (
              <>
                <Revolut onClick={handleRevolutPayment}>
                  <SectionTitle>
                    Zapłać za pomocą karty kredytowej:
                  </SectionTitle>
                  <img src={revolutLogo} alt={"Revolut"} />
                </Revolut>
              </>
            )}
        </OrderContainer>
        {order3C && (
          <div>
            <OrderPageSummary
              currency={order3C.currency.symbol}
              shipping={order3C.shippingcost}
              subtotal={order3C.itemsamount}
              total={order3C.grossamount}
              tax={order3C.taxamount}
            />
            <OrderPageDelivery
              name={`${order3C.shipaddressee} ${
                order3C.shipname && `(${order3C.shipname})`
              }`}
              address1={order3C.shipaddress}
              city={order3C.shipcity}
              country={order3C.shipcountry}
              phone={order3C.shipphone}
              zip={order3C.shipzip}
            />
          </div>
        )}
      </OrderWrapper>
      {error && (
        <UniversalModal text={error} clickHandler={modalClickHandler} />
      )}
    </Container>
  );
};

export default Order;

const OrderWrapper = styled.div`
  padding-bottom: 30px;
  display: grid;

  @media screen and (min-width: 1000px) {
    column-gap: 20px;
    grid-template-columns: 2fr 1fr;
  }
`;

const Revolut = styled.div`
  margin: 20px 0;
  padding: 30px 0;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: left;
  width: auto;
  border-top: 2px solid #dee2e6;

  img {
    height: 150px;
    width: auto;
    object-fit: contain;
    margin: 0 auto 0 0;
    &:hover {
      cursor: pointer;
      filter: brightness(80%);
    }
  }
`;

const SectionTitle = styled.p`
  text-transform: uppercase;
  font-weight: 700;
  color: #666666;
  font-size: 14px;
`;

const Paypal = styled.div`
  border-top: 2px solid #dee2e6;
  padding: 30px 0;
  margin: 20px 0;
`;

const OrderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSpan = styled.span`
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
`;
