import React from "react";
import styled from "styled-components";
import { PRIMARY } from "../../../vars/vars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons/faQuestionCircle";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const FormInput = ({ type, checkbox, children, placeholder, ...props }) => (
  <Wrapper>
    {props.textarea ? (
      <StyledTextarea {...props} rows={4} />
    ) : checkbox ? (
      <StyledCheckbox>
        <span className="checkbox__input">
          <input
            type="checkbox"
            {...props}
            invalid={props.invalid.toString()}
            valid={props.valid.toString()}
          />
          <span className="checkbox__control">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              focusable="false"
            >
              <path
                fill="none"
                stroke="#666666"
                strokeWidth="3"
                d="M1.73 12.91l6.37 6.37L22.79 4.59"
              />
            </svg>
          </span>
        </span>
        <span className="radio__label">{children}</span>
      </StyledCheckbox>
    ) : props.select ? (
      <StyledSelect {...props}>
        <option value={""}>{props.selectLabel}</option>
        {props.items.map(({ iso, name }) => (
          <option key={iso} value={iso}>
            {name}
          </option>
        ))}
      </StyledSelect>
    ) : (
      <StyledInput {...props} type={type || "text"} placeholder={placeholder} />
    )}
    {props.tooltip && (
      <OverlayTrigger
        delay={{
          show: 250,
          hide: 400,
        }}
        placement={"right"}
        overlay={<Tooltip id={"tooltip"}>{props.tooltip}</Tooltip>}
      >
        <Icon>
          <FontAwesomeIcon icon={faQuestionCircle} size={"lg"} />
        </Icon>
      </OverlayTrigger>
    )}
  </Wrapper>
);

export default FormInput;

const Icon = styled.div`
  margin-left: 20px;
  color: #bbb;

  &:hover {
    color: black;
  }
`;

const StyledCheckbox = styled.label`
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 0.5em;
  color: #666666;
  margin: 20px 0 10px;

  input {
    opacity: 0;
    width: 1.4em;
    height: 1.4em;
  }

  .checkbox__input {
    display: grid;
    grid-template-areas: "checkbox";

    > * {
      grid-area: checkbox;
    }
  }

  .checkbox__control {
    display: inline-grid;
    width: 1.4em;
    height: 1.4em;
    border-radius: 0.25em;
    border: 0.1em solid #666666;
    transform: translateY(5px);
  }

  .checkbox__control svg {
    transition: transform 0.1s ease-in 25ms;
    transform: scale(0);
    transform-origin: bottom left;
  }

  .checkbox__input input:checked + .checkbox__control svg {
    transform: scale(1);
  }

  .checkbox__input input:focus + .checkbox__control {
    box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em currentColor;
  }

  .checkbox__input input[type="checkbox"]:disabled + .checkbox__control {
    color: lightgray;
  }

  .checkbox--disabled {
    color: lightgray;
  }

  .radio__label {
    margin-top: 5px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 600px;
`;

const StyledTextarea = styled.textarea`
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) =>
    props.invalid ? PRIMARY : props.valid ? "rgb(40, 167, 69)" : "#adadad"};
  display: inline-block;
  width: 100%;
  background-color: #fff;
  border-radius: 1px;
  font-size: 12px;
  padding: 10px;
`;

const StyledSelect = styled.select`
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) =>
    props.invalid ? PRIMARY : props.valid ? "rgb(40, 167, 69)" : "#adadad"};
  height: 35px;
  display: inline-block;
  width: 100%;
  background-color: #fff;
  border-radius: 1px;
  font-size: 12px;
  padding: 0 10px;
  & > input {
    width 100%;
  }
  &::placeholder {
    color: #c2c2c2;
    font-size: 12px;
  }
`;

const StyledInput = styled.input`
  border-radius: 4px;
  padding: 0 9px;
  font-family: "Open Sans", sans-serif;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) =>
    props.invalid ? PRIMARY : props.valid ? "rgb(40, 167, 69)" : "#adadad"};
  height: 35px;
  display: inline-block;
  width: 100%;
  font-size: 12px;

  &:focus,
  &:active {
    outline: none;
  }

  .prefix {
    font-size: 40px;
  }
`;
