import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import layoutContext from "./../../../context/LayoutContext";
import { useAppContext } from "./../../../context/AppContext";
import { useConsentContext } from "./../../../context/ConstentContext";

const Backdrop = () => {
  const { initialAction, showCookiesManager } = useAppContext();
  const { cookiesConsentCtx } = useConsentContext();
  const { showDrawer, toggleDrawer } = useContext(layoutContext);

  const shouldShowBackdrop =
    initialAction &&
    (showDrawer || !cookiesConsentCtx.accepted) &&
    !showCookiesManager;

  const setOverflow = (value) => {
    document.body.style.overflow = value;
    document.getElementById("root").style.overflow = value;
  };

  useEffect(() => {
    setOverflow(shouldShowBackdrop ? "hidden" : "visible");
    return () => setOverflow("visible");
  }, [shouldShowBackdrop]);

  if (window.location.pathname === "/cookies" || !shouldShowBackdrop)
    return null;

  return <StyledBackdrop onClick={toggleDrawer} />;
};

export default Backdrop;

const StyledBackdrop = styled.div`
  background-color: #0000004d;
  z-index: 500;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
