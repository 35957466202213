import React from "react";
import styled from "styled-components";
import MiniBasketButton from "../../atoms/MiniBasketButton/MiniBasketButton";

const MiniBasketButtons = ({ closeMiniBasket }) => (
  <StyledMiniBasketButtons onClick={closeMiniBasket}>
    <MiniBasketButton to={"/basket"}>Koszyk</MiniBasketButton>
    <MiniBasketButton to={"/checkout"}>Zamów</MiniBasketButton>
  </StyledMiniBasketButtons>
);

export default MiniBasketButtons;

const StyledMiniBasketButtons = styled.div`
  display: flex;
  padding: 15px 20px;
  justify-content: space-between;
`;
