import React from "react";
import styled from "styled-components";
import { PRIMARY } from "../../../vars/vars";

const MiniBasketHeader = () => (
  <StyledComponent>
    <div>Produkt</div>
    <div>Cena</div>
  </StyledComponent>
);

export default MiniBasketHeader;

const StyledComponent = styled.div`
  padding: 8px 20px;
  display: flex;
  justify-content: space-between;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  background-color: ${PRIMARY};
`;
