export const loadLiveAgentScript = () => {
  (function (d, src, c) {
    var t = d.scripts[d.scripts.length - 1],
      s = d.createElement("script");
    s.id = "la_x2s6df8d";
    s.async = true;
    s.src = src;
    s.onload = s.onreadystatechange = function () {
      var rs = this.readyState;
      if (rs && rs !== "complete" && rs !== "loaded") {
        return;
      }
      c(this);
    };
    t.parentElement.insertBefore(s, t.nextSibling);
  })(document, "https://ozparts.ladesk.com/scripts/track.js", function (e) {
    window.LiveAgent.createButton("uu4k0w7z", e);
  });
};
