import React, { useState } from "react";
import styled from "styled-components";
import { PRIMARY } from "../../../vars/vars";
import { tabs } from "./tabs";
import "./DetailsSwitch.scss";
import Applications from "../../molecules/DetailsApplications/DetailsApplications";
import Technical from "../../molecules/DetailsTechnical/DetailsTechnical";
import DetailsRelated from "../../molecules/DetailsRelated/DetailsRelated";
import DetailsComponents from "../DetailsComponents/DetailsComponents";

const DetailsSwitch = ({ part }) => {
  const [activeTab, setActiveTab] = useState("Detale");

  const clickHandler = (tabName) => {
    setActiveTab(tabName);
  };

  const relatedItems = part.relateditems;

  const technicalInfo = part.technicaldetails.find(
    (item) => item.name === "Tech Info" || "Important"
  );

  const getContent = () => {
    switch (activeTab) {
      case "Detale":
        return (
          <>
            <div
              className={"details-html"}
              dangerouslySetInnerHTML={{ __html: part.detaildescription }}
            />
            {part.components && part.components.length ? (
              <DetailsComponents
                components={part.components}
                base={part.name}
              />
            ) : null}
          </>
        );
      case "Pasuje do":
        return <Applications applications={part.applications} />;
      case "Informacje techniczne":
        return <Technical part={part} />;
      case "Pokrewne części":
        return <DetailsRelated relatedItems={relatedItems} />;
      default:
        return null;
    }
  };

  return (
    <StyledDetailsSwitch>
      <Select>
        {tabs
          .filter(({ name }) =>
            name === "Technical Notes" ? !!technicalInfo : true
          )
          .filter(({ name }) =>
            name === "Related Items" ? !!relatedItems.length : true
          )
          .map(({ name }) => (
            <SelectItem
              key={name}
              isActive={activeTab === name}
              onClick={() => clickHandler(name)}
            >
              {name}
            </SelectItem>
          ))}
      </Select>
      <Content>{getContent()}</Content>
    </StyledDetailsSwitch>
  );
};

export default DetailsSwitch;

const Content = styled.div`
  padding: 20px;
  border: 1px solid #ddd;
  width: 100%;
`;
const Select = styled.div``;
const SelectItem = styled.div`
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: #444;
  padding: 15px 30px;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-left: ${(props) =>
    props.isActive ? `4px solid ${PRIMARY}` : "1px solid #ddd"};

  &:hover {
    cursor: pointer;
  }

  &:first-of-type {
    border-top: 1px solid #ddd;
  }
`;

const StyledDetailsSwitch = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1000px) {
    flex-direction: row;
  }
`;
