import React from "react";
import styled from "styled-components";
import BasketLine from "../../molecules/BasketLine/BasketLine";
import BasketKit from "../../molecules/BasketKit/BasketKit";
import { Link } from "react-router-dom";
import { PRIMARY } from "../../../vars/vars";
import { useAppContext } from "../../../context/AppContext";

const BasketCart = ({ updateHandler, deleteHandler, isLoading }) => {
  const { basketId, basket } = useAppContext();

  return (
    <Component>
      <StyledTable>
        <TableHeader>Przedmiot</TableHeader>
        <TableHeader>Cena</TableHeader>
        <TableHeader>Ilość</TableHeader>
        <TableHeader>Suma częściowa</TableHeader>
        {basket &&
          basket.transactionlines
            .filter((item) => item.kitcomponent !== true)
            .map((item) =>
              item.item.recordtype === "kititem" ? (
                <BasketKit
                  isLoading={isLoading}
                  deleteHandler={deleteHandler}
                  changeHanlder={updateHandler}
                  key={item._id}
                  item={item}
                  basketId={basketId}
                  currency={basket.currency.symbol}
                />
              ) : (
                <BasketLine
                  isLoading={isLoading}
                  deleteHandler={deleteHandler}
                  changeHanlder={updateHandler}
                  key={item._id}
                  item={item}
                  basketId={basketId}
                  currency={basket.currency.symbol}
                />
              )
            )}
      </StyledTable>
      {basket && !!basket.backorderquantity ? (
        <StyledBackorderInfo>
          <Delivery>
            <p>
              Ponieważ co najmniej jeden z produktów ma wydłużony czas
              realizacji, całe zamówienie zostanie zrealizowane po
              skompletowaniu
            </p>
            <p>
              * dla produktów dostępnych w magazynie standardowy czas dostawy
              wynosi od 2 do 10 dni, w zależności
              <Link
                to="/shipping"
                target="_blank"
                className="gtm_btn_shippingoptions"
              >
                {" "}
                od kraju docelowego
              </Link>
            </p>
          </Delivery>
        </StyledBackorderInfo>
      ) : (
        <StyledBackorderInfo>
          <Delivery>
            <p>
              * dla produktów dostępnych w magazynie standardowy czas dostawy
              wynosi od 2 do 10 dni, w zależności
              <Link
                to="/shipping"
                target="_blank"
                className="gtm_btn_shippingoptions"
              >
                {" "}
                od kraju docelowego
              </Link>
            </p>
          </Delivery>
        </StyledBackorderInfo>
      )}
      <Continue to={"/"} className="gtm_btn_continueshopping">
        Kontynuuj zakupy
      </Continue>
    </Component>
  );
};

export default BasketCart;

const Delivery = styled.div`
  display: flex;
  align-items: left;
  margin-bottom: 10px;
  font-weight: 600;
  flex-direction: column;
`;

const Component = styled.section`
  grid-area: basket;
`;

const Continue = styled(Link)`
  display: inline-block;
  font-weight: 500;
  border-radius: 0;
  height: 36px;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 36px;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  color: white;
  background-color: ${PRIMARY};
  text-transform: uppercase;
  padding: 0 20px;

  &:hover {
    background-color: #444444;
    color: white;
  }
`;

const StyledTable = styled.div`
  display: grid;
  grid-template-columns: 4.5fr 1fr 0.8fr 1fr;
`;

const TableHeader = styled.div`
  color: #666666;
  font-size: 12px;
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 10px;
  font-weight: 700;
  &:not(:first-of-type) {
    text-align: right;
  }
  display: none;

  @media (min-width: 767px) {
    display: block;
  }
`;

const StyledBackorderInfo = styled.div`
  margin: 20px 0;
  font-size: 14px;
`;
