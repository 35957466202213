import { faHeart } from "@fortawesome/free-solid-svg-icons/faHeart";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons/faPhoneAlt";
import { faFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default [
  {
    path: "/about",
    text: "O nas",
    icon: <FontAwesomeIcon icon={faHeart} />,
  },
  {
    path: "https://www.facebook.com/PeddersPolska",
    text: "Facebook",
    direct: true,
    icon: <FontAwesomeIcon icon={faFacebook} />,
  },
  {
    path: "https://www.instagram.com/pedderspolska_/",
    text: "Instagram",
    direct: true,
    icon: <FontAwesomeIcon icon={faInstagram} />,
  },
  {
    path: "/contact",
    text: "Kontakt",
    icon: <FontAwesomeIcon icon={faPhoneAlt} />,
  },
];
