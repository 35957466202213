import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { BREAKPOINT, PRIMARY, SECONDARY } from "../../../vars/vars";

const TopbarItem = ({ icon, text, path, direct }) => {
  return direct ? (
    <StyledA href={path} target={"_blank"} rel={"noopener noreferrer nofollow"}>
      <IconSocial>{icon}</IconSocial>
      <TextSocial>{text}</TextSocial>
    </StyledA>
  ) : (
    <StyledLink
      to={path}
      className={path === "/contact" && "gtm_btn_contactus"}
    >
      <Icon style={{ pointerEvents: "none" }}>{icon}</Icon>
      <Text style={{ pointerEvents: "none" }}>{text}</Text>
    </StyledLink>
  );
};

export default TopbarItem;

const StyledA = styled.a`
  color: white;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  padding: 0 20px;
  text-align: center;

  &:not(:last-of-type) {
    border-right: 1px solid ${SECONDARY};
  }

  &:hover {
    color: ${PRIMARY};
    cursor: pointer;
  }
`;

const StyledLink = styled(Link)`
  color: white;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  padding: 0 20px;
  text-align: center;

  &:not(:last-of-type) {
    border-right: 1px solid ${SECONDARY};
  }

  &:hover {
    color: ${PRIMARY};
    cursor: pointer;
  }
`;

const Text = styled.span`
  @media screen and (min-width: ${BREAKPOINT}) {
    margin-left: 10px;
  }
`;

const Icon = styled.span`
  display: none;
  @media screen and (min-width: ${BREAKPOINT}) {
    display: inline-block;
  }
`;

const TextSocial = styled.span`
  display: none;
  @media screen and (min-width: ${BREAKPOINT}) {
    display: inline-block;
    margin-left: 10px;
  }
`;

const IconSocial = styled.span``;
