import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useAppContext } from "../../../context/AppContext";
import { COOKIESNAME } from "./../../../vars/vars";

import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import "./CookieManager.scss";

const CookieSettingsDialog = ({ onSavePreferences }) => {
  const { showCookiesManager, setShowCookiesManager } = useAppContext();
  const [cookiePreferences, setCookiePreferences] = useState([
    { label: "Niezbędne", enabled: true, mandatory: true },
    { label: "Funkcjonalne", enabled: false, mandatory: false },
    { label: "Statystyczne", enabled: false, mandatory: false },
    { label: "Marketingowe", enabled: false, mandatory: false },
  ]);
  const [show, setShow] = useState(false);

  const acceptAll = () => {
    const aceptedAll = cookiePreferences.map((obj) => ({
      ...obj,
      enabled: true,
    }));

    setCookiePreferences(aceptedAll);
    emitPreferences(aceptedAll);
  };

  const getCookies = (name) => {
    const consents = Cookies.get(name);
    return consents ? JSON.parse(consents) : null;
  };

  const emitPreferences = (preferences) => {
    const currentPreferences = preferences.map((pref) => ({
      label: pref.label,
      enabled: pref.enabled,
    }));

    onSavePreferences(currentPreferences);
    handleClose();
  };

  const saveAndClose = () => {
    emitPreferences(cookiePreferences);
  };

  useEffect(() => {
    updatePreferencesFromCookies();
  }, []);

  const updatePreferencesFromCookies = () => {
    const cookies = getCookies(COOKIESNAME);
    if (cookies && cookies.accepted) {
      updateCookiePreferences(cookies.preferences);
    }
  };

  const updateCookiePreferences = (data) => {
    setCookiePreferences((prev) =>
      prev.map((pref) => ({
        ...pref,
        enabled: pref.mandatory || data.includes(pref.label),
      }))
    );
  };

  const handleClose = () => {
    setShow(false);
  };

  const handlePreferenceChange = (index) => {
    setCookiePreferences((prev) =>
      prev.map((pref, i) =>
        i === index ? { ...pref, enabled: !pref.enabled } : pref
      )
    );
  };

  useEffect(() => {
    setShow(showCookiesManager);
    setShowCookiesManager(showCookiesManager);
    updatePreferencesFromCookies();
  }, [showCookiesManager]);

  return (
    <Modal show={show} size="xs" centered animation onHide={handleClose}>
      <Modal.Header className="customTheme">
        <Modal.Title>Ustawienia Cookies</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <h5 className="font-weight-bold mb-3">Prywatność</h5>
        <p className="mb-3">
          Poniżej możesz dostosować ustawienia dotyczące plików cookies zarówno
          naszych, jak i naszych partnerów. Abyśmy mogli korzystać z
          analitycznych oraz marketingowych plików cookies, potrzebujemy Twojej
          zgody.
        </p>
        <p className="mb-3">
          Jeśli chcesz zaakceptować wszystkie pliki cookie, kliknij „Zaakceptuj
          wszystkie”. Jeśli wolisz, abyśmy korzystali tylko z niezbędnych plików
          cookie, wybierz „Zapisz ustawienia i zamknij”. Aby zmienić preferencje
          dotyczące plików cookie, przesuń suwak przy odpowiedniej kategorii.
          Masz prawo do wglądu oraz modyfikacji swoich ustawień w dowolnym
          momencie.
        </p>
        <p className="mb-3">
          Korzystanie z plików cookie wiąże się z przetwarzaniem Twoich danych
          osobowych dotyczących aktywności w serwisie. Szczegółowe informacje na
          temat sposobu, w jaki my i nasi partnerzy używamy plików cookie i
          przetwarzamy Twoje dane, a także informacje o Twoich prawach,
          znajdziesz w naszej{" "}
          <Link to="/privacy" target="_blank" className="customLinkColor">
            Polityce Cookies.
          </Link>
        </p>
        <h5 className="font-weight-bold mb-3">
          Zarządzaj ustawieniami cookies
        </h5>
        <Form>
          {cookiePreferences.map((preference, index) => (
            <Form.Group
              key={index}
              className="d-flex justify-content-between align-items-center mb-2"
            >
              <Form.Check
                type="switch"
                id={`custom-switch-${index}`}
                label={preference.label}
                checked={preference.enabled}
                onChange={() => handlePreferenceChange(index)}
                disabled={preference.mandatory}
                className="custom-switch"
              />
            </Form.Group>
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-column">
        <Button variant="danger" onClick={() => acceptAll()} className="w-100">
          ZAAKCEPTUJ WSZYSTKIE
        </Button>
        <Button variant="secondary" onClick={saveAndClose} className="w-100">
          ZAPISZ USTAWIENIA I ZAMKNIJ
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CookieSettingsDialog;
