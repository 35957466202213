import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons/faAngleUp";

const SummaryDropdown = ({ children, text }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div>
      <Dropdown onClick={() => setIsActive((prev) => !prev)}>
        <div>{text}</div>
        <div>
          {isActive ? (
            <FontAwesomeIcon icon={faAngleUp} />
          ) : (
            <FontAwesomeIcon icon={faAngleDown} />
          )}
        </div>
      </Dropdown>
      {isActive ? children : null}
    </div>
  );
};

export default SummaryDropdown;

const Dropdown = styled.div`
  display: flex;
  padding: 6px 10px;
  background: #fafafa;
  border: 1px solid #ddd;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
  color: #666666;
  cursor: pointer;
`;
