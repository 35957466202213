import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons/faCheckSquare";

const calculateDaysToDeliver = (date, delayDays = 0) => {
  const currentDate = new Date();
  const deliveryDate = new Date(date);

  const differenceInMilliseconds = deliveryDate - currentDate;
  const daysToDeliver = Math.ceil(
    differenceInMilliseconds / (24 * 60 * 60 * 1000)
  );

  const availableInDays = daysToDeliver;
  const availableInDaysWithDelay = daysToDeliver + delayDays;

  return `Dostawa w ciągu ${availableInDays}-${availableInDaysWithDelay} dni`;
};

const InTransit = ({ date, overThreeWeeks }) => (
  <>
    <Header>
      <FontAwesomeIcon icon={faCheckSquare} color={"#50be19"} />
      <StyledStock>W trakcie transportu</StyledStock>
    </Header>

    <StyledAvl>
      {`${overThreeWeeks}`
        ? calculateDaysToDeliver(date, 7)
        : "Dostępne w ciągu 2–3 tygodni"}
    </StyledAvl>
  </>
);

export default InTransit;

const Header = styled.div`
  display: flex;
  font-size: 22px;
  gap: 5px;
  align-items: center;
`;

const StyledStock = styled.div`
  color: #50be19;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
`;

const StyledAvl = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: rgb(102, 102, 102);
`;
