import React, { useEffect, useState } from "react";
import DetailsPhotoSwitch from "../DetailsPhotoSwitch/DetailsPhotoSwitch";
import DetailsActivePhoto from "../DetailsActivePhoto/DetailsActivePhoto";

const defaultPhoto = {
  url: "https://3cerp.eu/api/file/pedders-logo.png",
};

const DetailsPhoto = ({ photos, name }) => {
  const [activePhoto, setActivePhoto] = useState(
    (photos && photos[0]) || defaultPhoto
  );

  useEffect(() => {
    setActivePhoto((photos && photos[0]) || defaultPhoto);
  }, [photos]);

  return (
    <div>
      <DetailsActivePhoto
        name={name}
        isPhoto={!!(photos && photos[0])}
        photos={photos}
        activePhoto={activePhoto}
      />
      {photos.length > 1 && (
        <DetailsPhotoSwitch
          photos={photos}
          name={name}
          clickHandler={setActivePhoto}
        />
      )}
    </div>
  );
};

export default DetailsPhoto;
