import React from "react";
import styled from "styled-components";
import SummaryHeader from "../../atoms/SummaryHeader/SummaryHeader";

const OrderPageDelivery = ({ name, address1, city, zip, country, phone }) => (
  <StyledComponent>
    <SummaryHeader text={"Adres dostawy"} />
    <p>{name}</p>
    <p>{address1}</p>
    <p>
      {city}, {zip}
    </p>
    <p>{country}</p>
    <p>{phone}</p>
  </StyledComponent>
);

export default OrderPageDelivery;

const StyledComponent = styled.section`
  margin-top: 30px;
  padding: 30px;
  background-color: #fafafa;
  font-size: 12px;
  color: #666666;
  font-family: "Open Sans", sans-serif;

  p {
    margin-bottom: 5px;

    &:first-of-type {
      margin-top: 20px;
    }
  }
`;
